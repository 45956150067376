import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { TIMEOUT_ON_APP_INIT_FOR_DELAYED_EVENTS } from '../utils/constants';

export const ClickScrollDetectionContext = createContext<boolean | undefined>(undefined);

export const UseClickScrollDetectionProvider = ({ children }: PropsWithChildren) => {
  const [isInteracted, setIsInteracted] = useState(false);
  const [isClickScrollDetected, setIsClickScrollDetected] = useState(false);
  const [isPageExcepted, setIsPageExcepted] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    const pageException = [
      '/auth/callback',
      '/shopping-cart',
      '/account/orders',
      '/account/profile',
      '/account/reviews',
      '/account/favorites',
      '/account/extra-warranty'
    ];

    setIsPageExcepted(pageException.some(path => path === pathname));
  }, [pathname]);

  useEffect(() => {
    // Exclude for AWS rendertron with user agent HeadlessChrome snapshots
    const userAgent = navigator.userAgent;

    setIsClickScrollDetected(isInteracted || isPageExcepted || userAgent.includes('Headless'));
  }, [isInteracted, isPageExcepted]);

  useEffect(() => {
    if (isClickScrollDetected) {
      return;
    }

    const handleTouch = () => {
      setIsInteracted(true);
    };

    document.addEventListener('click', handleTouch, { once: true });
    document.addEventListener('touchstart', handleTouch, { once: true });
    document.addEventListener('scroll', handleTouch, { once: true });
    document.addEventListener('mousemove', handleTouch, { once: true });

    return () => {
      document.removeEventListener('click', handleTouch);
      document.removeEventListener('touchstart', handleTouch);
      document.removeEventListener('scroll', handleTouch);
      document.removeEventListener('mousemove', handleTouch);
    };
  }, [isClickScrollDetected, setIsInteracted]);

  useEffect(() => {
    if (isClickScrollDetected) {
      return;
    }

    const timeoutId = setTimeout(() => {
      setIsClickScrollDetected(true);
    }, TIMEOUT_ON_APP_INIT_FOR_DELAYED_EVENTS);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isClickScrollDetected]);

  return (
    <ClickScrollDetectionContext.Provider value={isClickScrollDetected}>
      {children}
    </ClickScrollDetectionContext.Provider>
  );
};

export const useClickScrollDetection = (): boolean => {
  const isClickScrollDetected = useContext(ClickScrollDetectionContext);

  if (isClickScrollDetected === undefined) {
    throw new Error('useClickScrollDetection must be used inside a ClickScrollDetectionContext');
  }

  return isClickScrollDetected;
};
