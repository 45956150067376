import { AUTH_HEADER_NAME } from '@hypercharge/machineland-commons/lib/constants';
import jwtDecode, { type JwtPayload } from 'jwt-decode';
import React, {
  createContext,
  type PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

import { fetchUserInfo, openLoginPage, openLogoutPage, refreshTokens } from '../../actions/auth';
import { clearDefaultHeader, setDefaultHeader } from '../../utils/httpClient';
import { saveInLocalStorage } from '../../utils/storage';
import { wakeEvent } from '../online/wakeEvent';
import { history } from '../router/history';
import {
  type AuthData,
  buildBearerContent,
  getStoredAuthData,
  isTokenValid,
  storeAuthData
} from './utils';

type ContextValue = {
  isAuthenticated: boolean;
  userId?: string;
  login: () => void;
  logout: () => void;
  fetchExternalUserProfile: () => Promise<any>;
  setSession: (toResp?: TokenResp) => void;
};

type TokenResp = {
  access_token: string;
  expires_in: number;
  id_token: string;
  refresh_token: string;
};

// https://auth0.com/docs/users/guides/redirect-users-after-login
export const redirectAfterAuthKey = 'authRedirectTo';

const AuthContext = createContext<ContextValue | undefined>(undefined);

const storedData = getStoredAuthData();

// if we dont set the header here then when you refresh the page
// requests will fire without the header despite being authenticated
storedData &&
  isTokenValid(storedData.expiresAt) &&
  setDefaultHeader(AUTH_HEADER_NAME, buildBearerContent(storedData.idToken));

type PropsT = PropsWithChildren;

const AuthProvider = ({ children }: PropsT) => {
  const [authData, setAuthData] = useState<AuthData | undefined>(() => getStoredAuthData());

  const login = useCallback(() => {
    saveInLocalStorage(redirectAfterAuthKey, history.location.pathname);
    openLoginPage(window.location.origin);
  }, []);

  const setSession = useCallback((tokenResp?: TokenResp) => {
    if (!tokenResp) {
      setAuthData(undefined);

      return;
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { id_token, expires_in, access_token, refresh_token } = tokenResp;
    const newAuthData: AuthData = {
      idToken: id_token,
      expiresAt: expires_in * 1000 + new Date().getTime(),
      accessToken: access_token,
      refreshToken: refresh_token,
      // jwt-decode is used because its only 300bytes for the client
      userId: jwtDecode<JwtPayload>(id_token).sub
    };

    setAuthData(newAuthData);
    // if we don't set the header here then on login the shopping cart is fetched without
    // the header because only in the next cycle will the useEffect actually set the token
    setDefaultHeader(AUTH_HEADER_NAME, buildBearerContent(newAuthData.idToken));

    return newAuthData;
  }, []);

  // fetch user data from aws cognito
  const fetchExternalUserProfile = useCallback(async () => {
    if (authData) {
      try {
        const resp = await fetchUserInfo(authData.idToken);

        return await resp.json();
      } catch (e: unknown) {
        console.error(e);
      }
    }

    return null;
  }, [authData]);

  const logout = useCallback(
    async (executeExternalLogout = true): Promise<void> => {
      if (authData != null) {
        saveInLocalStorage(redirectAfterAuthKey, '/');
        // need to cleanup before other requests get fired
        clearDefaultHeader(AUTH_HEADER_NAME);
        executeExternalLogout && openLogoutPage(window.location.origin);
      }
    },
    [authData]
  );

  const refreshJwt = useCallback(async (): Promise<AuthData> => {
    return await new Promise((resolve, reject) => {
      authData &&
        refreshTokens(authData.refreshToken, window.location.origin)
          .then(resp => {
            resp
              .json()
              .then(data => {
                setSession({
                  refresh_token: authData.refreshToken,
                  ...data
                });
              })
              .catch(e => {
                void logout(false);
              });
          })
          .catch(e => {
            void logout(false);
          });
    });
  }, [logout, setSession, authData]);

  // this effects is responsible for persisting auth data
  useEffect(() => {
    storeAuthData(authData);
  }, [authData]);

  // this effect updates the Authorization headers used on all HTTP requests
  useEffect(() => {
    const cleanup = () => {
      clearDefaultHeader(AUTH_HEADER_NAME);
    };

    if (authData != null) {
      setDefaultHeader(AUTH_HEADER_NAME, buildBearerContent(authData.idToken));
    } else {
      cleanup();
    }

    return () => {
      cleanup();
    };
  }, [authData]);

  const scheduleRefresh = useCallback(() => {
    let timer: NodeJS.Timeout;

    if (authData) {
      const ttl = authData.expiresAt - Date.now();

      timer = setTimeout(refreshJwt, ttl < 30000 ? 0 : ttl - 30000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [authData, refreshJwt]);

  // this effect is responsible for refreshing the jwt before it expires
  useEffect(scheduleRefresh, [scheduleRefresh]);

  // when the computer wakes resume refresh
  useEffect(() => {
    wakeEvent(() => {
      scheduleRefresh();
    });
  }, [scheduleRefresh]);

  const isAuthenticated = authData != null && isTokenValid(authData.expiresAt);
  const userId = isAuthenticated && authData ? authData.userId : undefined;

  const contextObject = useMemo(
    () => ({ isAuthenticated, userId, login, logout, fetchExternalUserProfile, setSession }),
    [isAuthenticated, userId, login, logout, fetchExternalUserProfile, setSession]
  );

  return <AuthContext.Provider value={contextObject}>{children}</AuthContext.Provider>;
};

const useAuth = (): ContextValue => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};

export { AuthProvider, useAuth };
