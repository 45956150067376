import React, { FC, useEffect, useState } from 'react';
import { MdKeyboardArrowRight } from '@react-icons/all-files/md/MdKeyboardArrowRight';
import { Link } from 'react-router-dom';
import { CATEGORY_LIST_SEO, opOpTitle } from '../utils/constants';
import { useCategoryGroups } from '../context/secondaryUseQueryProviders';
import Skeleton from 'react-loading-skeleton';
import { PageWithWrapper } from './Common';
import styles from './Footer.module.scss';
import { MenuItem } from '../utils/types';
import { useClickScrollDetection } from '../context/useClickScrollDetectionProvider';
import JsCounters from './JsCounters';

const currentYear = new Date().getFullYear();

const scrollToTop = () => {
  try {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    // some browsers don't seem to handle the
    // smooth behaviour and throw an error
  } catch (e: unknown) {
    window.scrollTo(0, 0);
  }
};
const isProd = process.env.NODE_ENV === 'production';

const footerFirstColumnLinks: MenuItem[] = [
  { to: '/about', text: 'Over ons' },
  { to: '/support/opening-hours/', text: 'Openingsuren' },
  { to: '/support/', text: 'Klantenservice' },
  { to: '/brands', text: 'Merken' },
  { to: '/vacancies', text: 'Vacatures' },
  { to: '/support/contact', text: 'Contact' }
];

const footerSecondColumnLinks: MenuItem[] = [
  { to: '/categories', text: 'Hoofdcategorieën' },
  { to: '/clearance', text: opOpTitle },
  { to: '/account/extra-warranty', text: 'Extra garantie' },
  { to: '/service', text: 'Service' }
];

const Footer: FC = () => {
  const isShownAllComponents = useClickScrollDetection();
  const { data: categoryGroups, isLoading: isLoadingCategoryGroups } = useCategoryGroups({
    enabled: isShownAllComponents
  });

  const [categoryGroupList, setCategoryGroupList] = useState(CATEGORY_LIST_SEO);

  useEffect(() => {
    setCategoryGroupList(isShownAllComponents ? categoryGroups : CATEGORY_LIST_SEO);
  }, [isShownAllComponents, categoryGroups]);

  return (
    <div className={styles.footerWrapper}>
      <div className={styles.footer}>
        <PageWithWrapper>
          <div className={styles.navigationLinks}>
            <div>
              <img
                className={styles.logo}
                src="/images/logo-highlight.svg"
                alt="Logo Machineland"
                onClick={scrollToTop}
              />
            </div>

            <div>
              <ul>
                {footerFirstColumnLinks.map((link, index) => (
                  <li key={index}>
                    <Link to={link.to || '/'}>
                      {link.text}

                      <MdKeyboardArrowRight className={styles.navigationArrow} />
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

            <div>
              <ul>
                {footerSecondColumnLinks.map((link, index) => (
                  <li key={index}>
                    <Link to={link.to || '/'}>
                      {link.text}

                      <MdKeyboardArrowRight className={styles.navigationArrow} />
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

            <div className={styles.categories}>
              <ul>
                {isLoadingCategoryGroups ? (
                  <>
                    <Skeleton height="400px" width="100%" />
                  </>
                ) : (
                  categoryGroupList?.map(({ title, slug }) => (
                    <li key={slug}>
                      <Link to={`/categories/${slug}`}>{title}</Link>
                    </li>
                  ))
                )}
              </ul>
            </div>
          </div>
        </PageWithWrapper>
      </div>

      <div className={styles.subFooter} id="subfooter">
        <PageWithWrapper>
          <Link to="/terms-conditions">Algemene voorwaarden</Link>

          <span style={{ marginLeft: '0.25rem' }}>- © Copyright</span>

          <span style={{ marginLeft: '0.25rem' }}>{currentYear}</span>

          <span style={{ marginLeft: '0.25rem' }}>Machineland</span>
        </PageWithWrapper>
      </div>

      <div className="cp-hide">{navigator.userAgent}</div>

      {isProd && isShownAllComponents && <JsCounters />}
    </div>
  );
};

export default Footer;
