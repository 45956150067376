import { useQuery } from 'react-query';
import { fetchBrands } from '../actions/brands';
import { fetchLabels } from '../actions/labels';
import { fetchBanners } from '../actions/banners';
import { fetchArticles } from '../actions/articles';
import { fetchVacancies } from '../actions/vacancies';
import {
  fetchOfflineProductAlternatives,
  fetchTopSoldProducts,
  searchRecommendedProducts
} from '../actions/products';
import { fetchCategoryGroups } from '../actions/categories';
import { fetchDescriptionsProductFilters } from '../actions/descriptions-product-filters';

type OptionsT = {
  fetchParameter?: any;
  enabled?: boolean;
};

const useQueryRequest = (
  fetchMethod,
  queryKey: string,
  options: OptionsT = { fetchParameter: null, enabled: true }
) =>
  useQuery(
    queryKey,
    () =>
      fetchMethod(options.fetchParameter)
        .promise.then(data => (data.results ? data.results : data))
        .catch(error => {
          throw new Error(`FetchMethod error: ${error.message}`);
        }),
    { staleTime: Infinity, enabled: options.enabled }
  );

const useArticles = (options?: OptionsT) => useQueryRequest(fetchArticles, 'articles', options);
const useBanners = (options?: OptionsT) => useQueryRequest(fetchBanners, 'banners', options);
const useBrands = (options?: OptionsT) => useQueryRequest(fetchBrands, 'brands', options);
const useLabels = (options?: OptionsT) => useQueryRequest(fetchLabels, 'labels', options);
const useDescriptionsProductFilters = (options?: OptionsT) =>
  useQueryRequest(fetchDescriptionsProductFilters, 'descriptions-product-filters', options);
const useTopSoldProducts = (options?: OptionsT) =>
  useQueryRequest(fetchTopSoldProducts, 'top-sold-products', options);
const useCategoryGroups = (options?: OptionsT) =>
  useQueryRequest(fetchCategoryGroups, 'category-groups', options);
const useVacancies = (options?: OptionsT) => useQueryRequest(fetchVacancies, 'vacancies', options);
const useRecommendedProducts = (options?: OptionsT) =>
  useQueryRequest(searchRecommendedProducts, 'recommended-products', options);
const useAlternativeProducts = (options?: OptionsT) =>
  useQueryRequest(fetchOfflineProductAlternatives, 'offline-product-alternatives', options);

export {
  useDescriptionsProductFilters,
  useRecommendedProducts,
  useAlternativeProducts,
  useTopSoldProducts,
  useCategoryGroups,
  useVacancies,
  useArticles,
  useBanners,
  useLabels,
  useBrands
};
