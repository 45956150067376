import React, { FC, useMemo } from 'react';
import { MdFavoriteBorder } from '@react-icons/all-files/md/MdFavoriteBorder';
import { MdLocalPhone } from '@react-icons/all-files/md/MdLocalPhone';
import { Link, NavLink } from 'react-router-dom';
import LoadingDots from '../components/LoadingDots';
import AppSearch from '../products/AppSearch';
import ShoppingCart from '../shopping-cart/ShoppingCart';
import { PageWithWrapper } from './Common';
import style from './Header.module.scss';
import { MenuItem } from '../utils/types';
import { User } from '@hypercharge/machineland-commons/lib/types/user';
import {
  CATEGORY_ITEM_MENU,
  COMMON_MENU_ITEMS,
  LOGO_SVG,
  SEARCH_PLACEHOLDER
} from '../utils/constants';
import Search from 'antd/es/input/Search';
import ShoppingCartLight from '../shopping-cart/ShoppingCartLight';

type PropsT = {
  isAuthenticated?: boolean;
  menuItemsDesktop?: MenuItem[];
  menuItemsMobile?: MenuItem[];
  mobileMenuOpen?: boolean;
  toggleMenu?: () => void;
  user?: User;
  login?: () => void;
};

const HeaderLight: FC<PropsT> = ({
  isAuthenticated = false,
  menuItemsDesktop = [CATEGORY_ITEM_MENU, ...COMMON_MENU_ITEMS],
  menuItemsMobile = [],
  mobileMenuOpen = false,
  toggleMenu,
  user,
  login
}) => {
  const isLayoutLight = !menuItemsMobile?.length;
  const userName = useMemo(
    () => (!user ? '' : `${user.firstName} ${user.lastName}`.trim()),
    [user]
  );

  return (
    <section className={style.headerWrapper} id="header">
      <div className={style.headerTop}>
        <PageWithWrapper className={style.menuWrapper}>
          <div>
            <NavLink to="/" className={style.logo}>
              <img src={LOGO_SVG} alt="Machineland Logo" height="69px" />
            </NavLink>
          </div>
          <div className={style.browser}></div>
          <div>
            <nav>
              {isLayoutLight ? (
                <Search
                  className={style.search}
                  placeholder={SEARCH_PLACEHOLDER}
                  enterButton
                  disabled
                />
              ) : (
                <AppSearch className={style.search} />
              )}
              <ul className={style.menu}>
                <li>
                  <Link to="/account/favorites" title="Verlanglijstje">
                    <MdFavoriteBorder className={style.favoriteIcon} />
                  </Link>
                </li>
                <li>
                  <NavLink exact to="/support/contact" title="Contact">
                    <MdLocalPhone className={style.phoneIcon} />
                  </NavLink>
                </li>
                <li>
                  {isLayoutLight ? (
                    <ShoppingCartLight className={style.shoppingCart} />
                  ) : (
                    <ShoppingCart className={style.shoppingCart} />
                  )}
                </li>
                <li>
                  {isAuthenticated ? (
                    <NavLink
                      className={style.authNavLink}
                      activeClassName={style.active}
                      to="/account"
                    >
                      {!user ? (
                        <LoadingDots />
                      ) : (
                        <div className={style.userWrapper}>{userName}</div>
                      )}
                    </NavLink>
                  ) : (
                    <NavLink
                      className={style.authNavLink}
                      activeClassName={style.active}
                      exact
                      to="/login"
                      onClick={e => {
                        e.preventDefault();
                        if (login) {
                          login();
                        }
                      }}
                    >
                      Login
                    </NavLink>
                  )}
                </li>
              </ul>
            </nav>
          </div>
        </PageWithWrapper>
      </div>
      <div className={style.mainMenu}>
        <PageWithWrapper className={style.menuWrapper}>
          <div>
            <nav>
              <ul className={style.menu}>
                {menuItemsDesktop.map((item, index) => (
                  <li key={index}>
                    {item.to ? (
                      <NavLink activeClassName={style.active} to={item.to}>
                        {item.text}
                      </NavLink>
                    ) : (
                      item.content
                    )}
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </PageWithWrapper>
      </div>
      <div className={style.mobileSearchArea}>
        {isLayoutLight ? (
          <Search placeholder={SEARCH_PLACEHOLDER} enterButton disabled />
        ) : (
          <AppSearch />
        )}
      </div>
      <div className={style.mobileMenu}>
        {isLayoutLight ? (
          <ShoppingCartLight className={style.mobileShoppingCart} />
        ) : (
          <ShoppingCart className={style.mobileShoppingCart} />
        )}
        <input
          type="checkbox"
          className={style.toggle}
          value="true"
          checked={mobileMenuOpen}
          onChange={() => {
            if (toggleMenu) {
              toggleMenu();
            }
          }}
        />
        <div className={style.button}>
          <span />
          <span />
          <span />
        </div>
        <nav>
          <ul className={style.mobileMenu}>
            {menuItemsMobile.map((item, index) => (
              <li key={index}>
                <NavLink
                  activeClassName={style.active}
                  to={item.to || '/'}
                  onClick={
                    item.onClick
                      ? item.onClick
                      : () => {
                          if (toggleMenu) {
                            toggleMenu();
                          }
                        }
                  }
                  exact={!!item.onClick}
                >
                  {item.text}
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </section>
  );
};

export default HeaderLight;
