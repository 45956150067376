"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StockCode = exports.INFINITE_STOCK = void 0;
exports.INFINITE_STOCK = -1;
var StockCode;
(function (StockCode) {
    StockCode["onlineStock"] = "ONLINE_STOCK";
    StockCode["warehouseStock"] = "WAREHOUSE_STOCK";
    StockCode["companyStock"] = "COMPANY_STOCK";
    StockCode["noStock"] = "NO_STOCK"; // > 5 days OR Unavailable if U or B product
})(StockCode = exports.StockCode || (exports.StockCode = {}));
