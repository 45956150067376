"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toSlug = exports.cleanQuery = exports.titleToUrlEncode = void 0;
var titleToUrlEncode = function (str) {
    return str ? encodeURIComponent(str.toLocaleLowerCase().replace(/%/g, '%25')) : '';
};
exports.titleToUrlEncode = titleToUrlEncode;
var cleanQuery = function (req) {
    var excludeParameters = ['q', 'page', 's'];
    var getVal = function (str) { return str.split('=')[0]; };
    return req
        .split('&')
        .filter(function (item) { return !excludeParameters.includes(getVal(item)); })
        .sort(function (a, b) { return a.localeCompare(b); })
        .join('&');
};
exports.cleanQuery = cleanQuery;
var toSlug = function (str) {
    return str
        .toLowerCase()
        .replace(/[\u00D1]/gi, 'n')
        .replace(/[\u00C0-\u00C5]/gi, 'a')
        .replace(/[\u00C8-\u00CB]/gi, 'e')
        .replace(/[\u00CC-\u00CF]/gi, 'i')
        .replace(/[\u00D2-\u00D6]/gi, 'o')
        .replace(/[\u00D9-\u00DC]/gi, 'u')
        .replace(/[^a-z0-9\-/ ]+/gi, ' ')
        .trim()
        .replace(/\s/g, '-')
        .replace(/[-]{2}/g, '');
};
exports.toSlug = toSlug;
