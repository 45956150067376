import notification from 'antd/es/notification';

type MessageOptions = {
  message?: string;
  duration?: number;
};

type NotificationFunction = (description: string, options?: MessageOptions) => void;

export const clearNotifications = () => {
  notification.destroy();
};

export const showSuccess: NotificationFunction = (description, options) => {
  const key = description + options?.message;

  notification.success({
    className: 'success-message',
    message: options?.message || 'Succes',
    description,
    key,
    onClick: () => {
      notification.destroy(key);
    }
  });
};

export const showError: NotificationFunction = (description, options) => {
  const key = description + options?.message;

  notification.error({
    className: 'error-message',
    message: options?.message || 'Fout',
    description,
    duration: 10,
    key,
    onClick: () => {
      notification.destroy(key);
    }
  });
};

export const showInfo: NotificationFunction = (description, options) => {
  const key = description + options?.message;

  notification.warning({
    className: 'info-message',
    message: options?.message || 'Info',
    description,
    duration: 10,
    key,
    onClick: () => {
      notification.destroy(key);
    }
  });
};
