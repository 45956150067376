import { Product } from '@hypercharge/machineland-commons/lib/types/products';
import React, { FC, Fragment } from 'react';
import styled from 'styled-components/macro';
import { media } from '../layout/styles';
import ProductList from './ProductList';
import { useRecommendedProducts } from '../context/secondaryUseQueryProviders';
import { Paper } from '../layout/Common';

const Wrapper = styled.div`
  min-height: 435px;
  width: 100%;

  h2 {
    margin-left: 1rem;
    margin-right: 1rem;

    ${media.md} {
      margin-left: 10px;
      margin-right: 0;
    }
  }
`;

type PropsT = {
  product: Product | undefined;
  onProductSelected?: () => void;
  withPaper?: boolean;
  showAutomaticRelated?: boolean;
  singleRow?: boolean;
  cols?: number;
};

const RelatedProducts: FC<PropsT> = ({
  product,
  onProductSelected,
  withPaper,
  showAutomaticRelated = true,
  singleRow = true,
  cols
}) => {
  const { data: recommendedProducts } = useRecommendedProducts();

  const hasAccessories = !!product?.accessories?.length;
  const PaperLayer = withPaper ? Paper : Fragment;

  return product && !hasAccessories && !showAutomaticRelated ? null : (
    <Wrapper>
      <h2>{!product || hasAccessories ? 'Accessoires' : 'Aanbevolen producten'}</h2>
      <PaperLayer>
        <ProductList
          singleRow={singleRow}
          cols={cols}
          products={
            product
              ? hasAccessories
                ? (product.accessories as Product[])
                : recommendedProducts || []
              : []
          }
          loadingPlaceholders={!product || (!hasAccessories && !recommendedProducts) ? 4 : 0}
          onProductSelected={onProductSelected}
        />
      </PaperLayer>
    </Wrapper>
  );
};

export default RelatedProducts;
