"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductLabel = void 0;
var ProductLabel;
(function (ProductLabel) {
    ProductLabel["BLACK_FRIDAY"] = "B";
    ProductLabel["SOLDEN"] = "S";
    ProductLabel["CLEARANCE"] = "U";
    ProductLabel["PROMO"] = "F";
    ProductLabel["HOMEPAGE"] = "H";
    ProductLabel["WEBSITE"] = "W";
    ProductLabel["PROF_MATERIAL"] = "P";
    ProductLabel["NET_PRICE"] = "N";
    ProductLabel["MISCELLANEOUS"] = "M";
    ProductLabel["CANT_BE_ORDER"] = "G";
    ProductLabel["CASHEBACK"] = "C";
})(ProductLabel = exports.ProductLabel || (exports.ProductLabel = {}));
