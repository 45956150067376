"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PRODUCTS_PER_PAGE = exports.TRANSPORT_PRODUCT_TITLE = exports.TRANSPORT_COST_THRESHOLD = exports.VACANCY_FORM_FILES_CONTENT_TYPES_WHITELIST = exports.BELGIUM_PROVINCIES = exports.COUNTRIES_OPTIONS = exports.ENTITY_VACANCIES = exports.DEFAULT_COUNTRY = exports.AUTH_HEADER_NAME = exports.ID_TOKEN_COOKIE = exports.ENTITY_DOMAIN_PROPERTY_ID = exports.ENTITY_OWNER_PROPERTY_ID = exports.ENTITY_ID_PROPERTY_ID = exports.TITLE_PROPERTY_ID = exports.CREATED_AT_PROPERTY_ID = exports.WHITELISTED_DEFINITION_IDS = exports.TENANT_ID = exports.MONTAGE_DELIVERY_SCHEDULE_DEFINITION_ID = exports.NET_PRICE_CONTACT_DEFINITION_ID = exports.SERVICE_FORM_DEFINITION_ID = exports.WARRANTY_CONTACT_DEFINITION_ID = exports.SITE_CONTACT_DEFINITION_ID = exports.PRODUCT_LABELS_DEFINITION_ID = exports.STRIPE_EVENT_DEFINITION_ID = exports.PRODUCT_FAVORITE_DEFINITION_ID = exports.PROMO_CODE_DEFINITION_ID = exports.ADDRESS_DEFINITION_ID = exports.ORDER_ITEM_DEFINITION_ID = exports.CATEGORY_GROUP_DEFINITION_ID = exports.CATEGORY_DEFINITION_ID = exports.BANNER_DEFINITION_ID = exports.ARTICLES_DEFINITION_ID = exports.DESCRIPTIONS_PRODUCT_FILTERS_DEFINITION_ID = exports.BRAND_DEFINITION_ID = exports.PRODUCT_DEFINITION_ID = void 0;
exports.PRODUCT_DEFINITION_ID = 'product';
exports.BRAND_DEFINITION_ID = 'brand';
exports.DESCRIPTIONS_PRODUCT_FILTERS_DEFINITION_ID = 'landingPage';
exports.ARTICLES_DEFINITION_ID = 'homepageSeoText';
exports.BANNER_DEFINITION_ID = 'mainBanner';
exports.CATEGORY_DEFINITION_ID = 'category';
exports.CATEGORY_GROUP_DEFINITION_ID = 'categoryGroup';
exports.ORDER_ITEM_DEFINITION_ID = 'orderItem';
exports.ADDRESS_DEFINITION_ID = 'address';
exports.PROMO_CODE_DEFINITION_ID = 'promoCode';
exports.PRODUCT_FAVORITE_DEFINITION_ID = 'productFavorite';
exports.STRIPE_EVENT_DEFINITION_ID = 'stripeEvent';
exports.PRODUCT_LABELS_DEFINITION_ID = 'labels';
exports.SITE_CONTACT_DEFINITION_ID = 'siteContact';
exports.WARRANTY_CONTACT_DEFINITION_ID = 'warrantyContact';
exports.SERVICE_FORM_DEFINITION_ID = 'serviceForm';
exports.NET_PRICE_CONTACT_DEFINITION_ID = 'netPriceContact';
exports.MONTAGE_DELIVERY_SCHEDULE_DEFINITION_ID = 'montageDeliverySchedule';
exports.TENANT_ID = 'machineland';
exports.WHITELISTED_DEFINITION_IDS = [exports.PRODUCT_DEFINITION_ID];
exports.CREATED_AT_PROPERTY_ID = 'createdAt';
exports.TITLE_PROPERTY_ID = 'title';
exports.ENTITY_ID_PROPERTY_ID = 'entityId';
exports.ENTITY_OWNER_PROPERTY_ID = 'entityOwner';
exports.ENTITY_DOMAIN_PROPERTY_ID = 'entityDomain';
exports.ID_TOKEN_COOKIE = 'idToken';
exports.AUTH_HEADER_NAME = 'Authorization';
exports.DEFAULT_COUNTRY = 'BE';
exports.ENTITY_VACANCIES = 'vacancy';
/*
  Addresses contain country property as an entity type, but it's handled as a static value in the client side
  to avoid new fetches, since Countries have a custom entityId and its title is basically "immutable" considering
  that we don't have multi-language support.
  If we add different behavior per country (Ex: Shipping cost) we might need to review this.
*/
exports.COUNTRIES_OPTIONS = [
    {
        value: 'BE',
        label: 'België'
    },
    {
        value: 'NL',
        label: 'Nederland'
    }
];
exports.BELGIUM_PROVINCIES = [
    {
        value: '',
        label: 'Selecteer uw provincie',
        disabled: true
    },
    {
        value: 'Antwerpen',
        label: 'Antwerpen'
    },
    {
        value: 'Brabant',
        label: 'Brabant'
    },
    {
        value: 'Limburg',
        label: 'Limburg'
    },
    {
        value: 'Oost-Vlaanderen',
        label: 'Oost-Vlaanderen'
    },
    {
        value: 'West-Vlaanderen',
        label: 'West-Vlaanderen'
    },
    {
        value: 'Andere',
        label: 'Andere'
    }
];
exports.VACANCY_FORM_FILES_CONTENT_TYPES_WHITELIST = [
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
];
// when the total cost in the shopping cart is less than this value, the transport cost value should be applied
exports.TRANSPORT_COST_THRESHOLD = 75;
exports.TRANSPORT_PRODUCT_TITLE = 'Transport artikel';
exports.PRODUCTS_PER_PAGE = 12;
