import { Category, CategoryGroup } from '@hypercharge/machineland-commons/lib/types/categories';
import find from 'lodash/find';
import capitalize from 'lodash/capitalize';

import { AggSearchParam } from '../context/search/SearchParamsProvider';
import {
  CATEGORIES_AGGREGATION_FIELD,
  TERM_FILTERS_PREFIX,
  BRAND_AGGREGATION_FIELD
} from './constants';
import { Brand } from '@hypercharge/machineland-commons/lib/types/products';
import { DescriptionsProductFilters } from '@hypercharge/machineland-commons/lib/types/descriptions-product-filters';
import { cleanQuery, toSlug } from '@hypercharge/machineland-commons/lib/utils/seo';

export const getCategoryFilterQueryString = (categoryTitle: string, categoryGroupSlug?: string) => {
  return `${TERM_FILTERS_PREFIX}_${CATEGORIES_AGGREGATION_FIELD}[]=${categoryTitle}${
    categoryGroupSlug ? '&group=' + categoryGroupSlug : ''
  }`;
};

export const getBrandFilterQueryString = (brandTitle: string) => {
  return `${TERM_FILTERS_PREFIX}_${BRAND_AGGREGATION_FIELD}[]=${brandTitle}&page=1&s=relevant`;
};

export const getSelectedCategoryAndGroup = (
  selectedGroup: string,
  aggParams: AggSearchParam[],
  categoryGroups?: CategoryGroup[]
) => {
  const selectedCategories = aggParams.filter(
    ({ field }) => field === CATEGORIES_AGGREGATION_FIELD
  );

  let singleCategorySelected = false; // used in Bread crumbs
  let category: Category | undefined;
  let group;

  if (
    selectedCategories.length === 1 &&
    (selectedCategories[0].data as string[]).length === 1 &&
    selectedGroup
  ) {
    singleCategorySelected = true;
    if (categoryGroups) {
      group = find(categoryGroups, { slug: selectedGroup });
      if (group) {
        const filterValue = (selectedCategories[0].data as string[])[0];

        category = find(group.categories, ({ title }) => {
          return title.toLocaleLowerCase() === filterValue;
        });
      }
    }
  }

  return {
    singleCategorySelected,
    group,
    category
  };
};

export const getTitleSelectedBrand = (aggParams: AggSearchParam[]): string | undefined => {
  const selectedBrands = aggParams.find(({ field }) => field === BRAND_AGGREGATION_FIELD);

  return selectedBrands?.data ? capitalize(selectedBrands.data[0]) : undefined;
};

export const getSelectedBrand = (
  aggParams: AggSearchParam[],
  brands: Brand[]
): Brand | undefined => {
  const title = getTitleSelectedBrand(aggParams);

  return title
    ? brands.find(brand => brand.title.toLowerCase() === title.toLowerCase())
    : undefined;
};

const isQuerySearchOnlyAllowedParameters = (query, allowedParameters) => {
  const filteredParameters = Object.keys(query).filter(key => allowedParameters.includes(key));

  return Object.keys(query).length === filteredParameters.length;
};

export const isPageTrueSearchBrand = query => {
  if (query['ft_brand.title'] && query['ft_brand.title'].length === 1) {
    if (!isQuerySearchOnlyAllowedParameters(query, ['ft_brand.title', 'q', 'page', 's'])) {
      return false;
    }
    // only first page available
    if (query.page && query.page > 1) {
      return false;
    }

    // only default order lowPrice for category
    return !(query.s && query.s !== 'relevant');
  }

  return false;
};

export const isPageTrueSearchCategory = query => {
  if (query.group && query['ft_categories.title'] && query['ft_categories.title'].length === 1) {
    if (!isQuerySearchOnlyAllowedParameters(query, ['ft_categories.title', 'group', 'page', 's'])) {
      return false;
    }
    // only first page available
    if (query.page && query.page > 1) {
      return false;
    }

    // only default order lowPrice for category
    return !(query.s && query.s !== 'lowPrice');
  }

  return false;
};

export const isPageTrueSearchPage = query => {
  // only first page available canonical URL
  if (query.page && query.page > 1) {
    return false;
  }

  // only default order lowPrice available canonical URL
  return !(query.s && query.s !== 'lowPrice');
};

export const getDescriptionForCustomSearchByQuery = (
  descriptions: DescriptionsProductFilters[],
  query: string
): DescriptionsProductFilters | null => {
  const description = descriptions.filter(
    d => cleanQuery(query.replace(/^\?/g, '')) === d.urlParameters
  );

  return description.length ? description[0] : null;
};

export const getDescriptionQueryForCustomSearchBySlug = (
  descriptions: DescriptionsProductFilters[],
  slug: string
): string => {
  const query = descriptions.filter(d => toSlug(slug) === toSlug(d.slug));

  // urlParameters - search parameters for redirect
  return query.length ? query[0].urlParameters : '';
};
