"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isStringArray = exports.isItems = exports.isItem = void 0;
var lodash_1 = require("lodash");
var isItem = function (data) {
    return (0, lodash_1.isObject)(data) && 'entityId' in data;
};
exports.isItem = isItem;
var isItems = function (items) {
    return items.every(exports.isItem);
};
exports.isItems = isItems;
function isStringArray(array) {
    return array.every(lodash_1.isString);
}
exports.isStringArray = isStringArray;
