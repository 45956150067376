import React, { FC } from 'react';
import { getClassName } from '../utils/helpers';
import style from './Loading.module.scss';

type PropsT = { className?: string };

const Loading: FC<PropsT> = ({ className }) => (
  <div className={getClassName('spinner', className, style.loader)}>
    <div className={style.rect1} />
    <div className={style.rect2} />
    <div className={style.rect3} />
    <div className={style.rect4} />
    <div className={style.rect5} />
  </div>
);

export default Loading;
