import { PaymentMethod } from '@hypercharge/machineland-commons/lib/types/orders';
import { Product } from '@hypercharge/machineland-commons/lib/types/products';
import { ShoppingCartItem } from '@hypercharge/machineland-commons/lib/types/shopping-cart';

export const recordAddToCart = (item: Product, quantity: number) => {
  window.dataLayer.push({
    event: 'addToCart',
    ecommerce: {
      currencyCode: 'EUR',
      add: {
        products: [
          {
            name: item.title,
            id: item.referenceNumber,
            price: item.price,
            brand: item.brand.title,
            quantity,
            category: item.categories?.title
          }
        ]
      }
    }
  });
};

export const recordRemoveFromCart = (item: Product, quantity: number) => {
  window.dataLayer.push({
    event: 'removeFromCart',
    ecommerce: {
      remove: {
        products: [
          {
            name: item.title,
            id: item.referenceNumber,
            price: item.price,
            brand: item.brand.title,
            quantity,
            category: item.categories?.title
          }
        ]
      }
    }
  });
};

export const recordProductListView = (products: Product[]) => {
  window.dataLayer.push({
    ecommerce: {
      currencyCode: 'EUR',
      impressions: products.map((product, i) => ({
        name: product.title,
        id: product.referenceNumber,
        price: product.price,
        brand: product.brand ? product.brand.title : undefined,
        position: i + 1,
        list: 'Search',
        category: product.categories?.title
      }))
    }
  });
};

export const recordProductCardView = (product: Product) => {
  window.dataLayer.push({
    event: 'productClick',
    ecommerce: {
      click: {
        products: [
          {
            name: product.title,
            id: product.referenceNumber,
            price: product.price,
            brand: product.brand ? product.brand.title : undefined,
            category: product.categories?.title,
            position: 1
          }
        ]
      }
    }
  });
};

export const recordCheckout = (values: any) => {
  window.dataLayer.push({
    event: 'checkout',
    ecommerce: {
      checkout: {
        actionField: { step: 1, option: values.shippingMethod }
      }
    }
  });
};

export const recordPurchase = (orderId: string, cart: ShoppingCartItem[], total: number) => {
  window.dataLayer.push({
    event: 'purchase',
    ecommerce: {
      purchase: {
        actionField: {
          id: orderId,
          affiliation: 'Online Store',
          revenue: total,
          shipping: 0
        },
        products: cart.map(({ item, quantity }) =>
          item && !item.isMiscellaneous
            ? {
                name: item.title,
                id: item.referenceNumber,
                price: item.price,
                brand: item.brand.title,
                quantity,
                category: item.categories?.title
              }
            : {}
        )
      }
    }
  });
};

export const recordCheckoutPaymentMethod = (paymentMethod: PaymentMethod) => {
  window.dataLayer.push({
    event: 'checkout',
    ecommerce: {
      checkout: {
        actionField: { step: 2, option: paymentMethod }
      }
    }
  });
};
