export const getImageUrl = (imageId: string | string[], width?: number, height?: number): string =>
  `${process.env.REACT_APP_IMAGES_ENDPOINT}/${btoa(
    JSON.stringify({
      key: `machineland/${imageId}`,
      edits: {
        resize: {
          width,
          height,
          fit: 'inside',
          withoutEnlargement: true
        }
      }
    })
  )}`;

export const isValidUrl = (url?: string): boolean => {
  if (!url) return false;

  try {
    return Boolean(new URL(url));
  } catch (_) {
    return false;
  }
};
