"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AggregationType = exports.FilterOperatorTypes = exports.ConditionType = exports.SortOrder = void 0;
var SortOrder;
(function (SortOrder) {
    SortOrder["asc"] = "ASC";
    SortOrder["desc"] = "DESC";
})(SortOrder = exports.SortOrder || (exports.SortOrder = {}));
var ConditionType;
(function (ConditionType) {
    ConditionType["and"] = "AND";
    ConditionType["or"] = "OR";
})(ConditionType = exports.ConditionType || (exports.ConditionType = {}));
var FilterOperatorTypes;
(function (FilterOperatorTypes) {
    FilterOperatorTypes["empty"] = "EMPTY";
    FilterOperatorTypes["notEmpty"] = "NOT_EMPTY";
    FilterOperatorTypes["equals"] = "EQUALS";
    FilterOperatorTypes["notEquals"] = "NOT_EQUALS";
    FilterOperatorTypes["greaterThanOrEqual"] = "GTE";
    FilterOperatorTypes["greaterThan"] = "GT";
    FilterOperatorTypes["lessThanEqual"] = "LTE";
    FilterOperatorTypes["lessThan"] = "LT";
    FilterOperatorTypes["is"] = "IS";
    FilterOperatorTypes["isNot"] = "IS_NOT";
    FilterOperatorTypes["contains"] = "CONTAINS";
    FilterOperatorTypes["notContains"] = "NOT_CONTAINS";
    FilterOperatorTypes["startsWith"] = "STARTS_WITH";
    FilterOperatorTypes["endsWith"] = "ENDS_WITH";
    // this is a hack to pass the value to the
    // aggregation service that expects {start, end} data
    FilterOperatorTypes["between"] = "BETWEEN";
})(FilterOperatorTypes = exports.FilterOperatorTypes || (exports.FilterOperatorTypes = {}));
var AggregationType;
(function (AggregationType) {
    AggregationType["term"] = "TERM";
    AggregationType["range"] = "RANGE";
})(AggregationType = exports.AggregationType || (exports.AggregationType = {}));
