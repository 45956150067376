import React, { FC, useMemo } from 'react';
import style from './Labels.module.scss';
import { Label } from '../../../commons/src/types/products';
import Tooltip from 'antd/es/tooltip';
import { useLabels } from '../context/secondaryUseQueryProviders';
import { isItems, isStringArray } from '@hypercharge/machineland-commons/lib/utils/common';

type PropsTLabelHide = {
  labels: Label[];
};

const LabelHideComponents: FC<PropsTLabelHide> = ({ labels }) => (
  <Tooltip
    title={
      <ul className={style.ul}>
        {labels.map(label => (
          <li key={label.title}>{label.title}</li>
        ))}
      </ul>
    }
  >
    <div className={`${style.badge} ${style.noBadge}`}>{`+${labels.length}`}</div>
  </Tooltip>
);

const LabelComponent: FC<{ label: Label }> = ({ label }) => (
  <Tooltip title={label.title}>
    <div
      className={style.badge}
      style={{
        color: label.labelTextColor || 'black',
        backgroundColor: label.labelColor ? label.labelColor + 'B0' : '#e0f4ee90'
      }}
    >
      {label.title}
    </div>
  </Tooltip>
);

type PropsT = {
  labels: Label[] | string[];
  maxVisibleLabels: number;
};

const Labels: FC<PropsT> = ({ labels, maxVisibleLabels }) => {
  const { data: fullReference } = useLabels();

  const mapped: Label[] = useMemo(() => {
    let result: Label[] = [];

    if (isItems(labels)) {
      result = labels;
    } else if (isStringArray(labels)) {
      result = labels
        .map((entityId: string) => fullReference?.find((item: Label) => item.entityId === entityId))
        .filter(item => item);
    }

    return result;
  }, [fullReference, labels]);

  const { visible, hidden } = useMemo(() => {
    const displayOnWebsite = mapped.filter(label => label.displayOnWebsite !== false);
    const visible = displayOnWebsite.slice(0, maxVisibleLabels);
    const hidden = displayOnWebsite.slice(maxVisibleLabels);

    return { visible, hidden };
  }, [mapped, maxVisibleLabels]);

  return (
    <div className={style.badgeWrapper}>
      {visible.map(label => (
        <LabelComponent key={label.entityId} label={label} />
      ))}

      {!!hidden.length && <LabelHideComponents labels={hidden} />}
    </div>
  );
};

export default Labels;
