import React, { FC } from 'react';
import style from './SplashPage.module.scss';
import Loading from '../components/Loading';

const SplashPage: FC = () => (
  <div className={style.splashLoader}>
    <Loading />
  </div>
);

export default SplashPage;
