import React, { FC } from 'react';
import style from './LoadingDots.module.scss';

const LoadingDots: FC = () => (
  <span className={style.loader}>
    <span className={style.one}>.</span>
    <span className={style.two}>.</span>
    <span className={style.three}>.</span>
  </span>
);

export default LoadingDots;
