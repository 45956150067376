import { Banner } from '@hypercharge/machineland-commons/lib/types/banners';
import { FrontendFile } from '@hypercharge/machineland-commons/lib/types/products';
import { MouseEventHandler, ReactNode } from 'react';

export enum Status {
  Idle,
  Loading,
  Error,
  Success
}

export type CarouselEntry = Banner & {
  description?: string;
};

export type MenuItem = {
  to?: string;
  text?: string;
  content?: ReactNode;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
};

export const isFrontendFileType = (
  files: Array<FrontendFile | string>
): files is FrontendFile[] => {
  return files.every(file => typeof file === 'object' && 'downloadUrl' in file);
};
