import React, { FC } from 'react';
import { MdShoppingCart } from '@react-icons/all-files/md/MdShoppingCart';
import { NavLink } from 'react-router-dom';
import { SLUG_SHOPPING_CARD_PAGE } from '../utils/constants';
import style from './ShoppingCart.module.scss';
import { getClassName } from '../utils/helpers';

type PropsT = {
  className?: string;
  count?: number;
};

const ShoppingCartLight: FC<PropsT> = ({ className, count }) => {
  return (
    <div className={getClassName(className, style.shoppingCart)}>
      <NavLink className={style.cartButton} to={SLUG_SHOPPING_CARD_PAGE}>
        <MdShoppingCart className={style.cartIcon} />
        {(count && <span>{count}</span>) || null}
      </NavLink>
    </div>
  );
};

export default ShoppingCartLight;
