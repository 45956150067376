"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckoutError = exports.Currency = exports.OrderStatus = exports.PaymentStatus = exports.ShippingMethod = exports.PaymentMethod = void 0;
var PaymentMethod;
(function (PaymentMethod) {
    PaymentMethod["bankTransfer"] = "bankTransfer";
    PaymentMethod["store"] = "store";
    PaymentMethod["stripe"] = "stripe";
    PaymentMethod["creditCard"] = "card";
    PaymentMethod["bancontact"] = "bancontact";
    PaymentMethod["unknown"] = "unknown";
})(PaymentMethod = exports.PaymentMethod || (exports.PaymentMethod = {}));
var ShippingMethod;
(function (ShippingMethod) {
    ShippingMethod["home"] = "home";
    ShippingMethod["emblem"] = "emblem";
    ShippingMethod["ekeren"] = "ekeren";
    ShippingMethod["lochristi"] = "lochristi";
    ShippingMethod["sintNiklaas"] = "sintNiklaas";
    ShippingMethod["gravenwezel"] = "gravenwezel";
})(ShippingMethod = exports.ShippingMethod || (exports.ShippingMethod = {}));
var PaymentStatus;
(function (PaymentStatus) {
    PaymentStatus["failed"] = "failed";
    PaymentStatus["pending"] = "pending";
    PaymentStatus["succeeded"] = "succeeded";
    PaymentStatus["unknown"] = "unknown";
})(PaymentStatus = exports.PaymentStatus || (exports.PaymentStatus = {}));
var OrderStatus;
(function (OrderStatus) {
    OrderStatus["shipped"] = "SHIPPED";
    OrderStatus["placed"] = "PLACED";
    OrderStatus["processing"] = "PROCESSING";
    OrderStatus["readyPickup"] = "READY_PICKUP";
    OrderStatus["delivered"] = "DELIVERED";
})(OrderStatus = exports.OrderStatus || (exports.OrderStatus = {}));
var Currency;
(function (Currency) {
    Currency["EUR"] = "EUR";
})(Currency = exports.Currency || (exports.Currency = {}));
var CheckoutError;
(function (CheckoutError) {
    CheckoutError["ORDER_STOCK_ERROR"] = "ORDER_STOCK_ERROR";
    CheckoutError["ORDER_COST_ERROR"] = "ORDER_COST_ERROR";
    CheckoutError["ORDER_ERROR"] = "ORDER_ERROR";
    CheckoutError["INVALID_PROMO_CODE"] = "INVALID_PROMO_CODE";
})(CheckoutError = exports.CheckoutError || (exports.CheckoutError = {}));
