import {
  AggregationType,
  SortFilter,
  SortOrder
} from '@hypercharge/machineland-commons/lib/types/search';
import { getFormattedPrice } from '@hypercharge/machineland-commons/lib/utils/price';
import capitalize from 'lodash/capitalize';
import { MenuItem } from './types';
import { Address } from '@hypercharge/machineland-commons/lib/types/common';
import { DEFAULT_COUNTRY } from '@hypercharge/machineland-commons/lib/constants';
import { belgium, netherlands } from 'jsvat';

// disabling pls for speed - if is necessary. module moment used only here
// export const isBlackFridayDateRange = moment().isBetween(`2021-11-19 23:59`, `2021-12-01 00:00`);
export const isBlackFridayDateRange = false;

export const opOpTitle = `${isBlackFridayDateRange ? 'Black Friday' : 'OP=OP'}`;

export const PRODUCT_PRICE_PROPERTY_ID = 'price';

export const DEFAULT_SORT_KEY = 'lowPrice';
export const RELEVANCE_SORT_KEY = 'relevant';
// export const LOW_PRICE_SORT_KEY = 'lowPrice';
export const HIGH_PRICE_SORT_KEY = 'highPrice';
export const SORT_BY_OPTIONS: Record<string, SortFilter> = {
  [DEFAULT_SORT_KEY]: {
    field: PRODUCT_PRICE_PROPERTY_ID,
    order: SortOrder.asc
  },
  [HIGH_PRICE_SORT_KEY]: {
    field: PRODUCT_PRICE_PROPERTY_ID,
    order: SortOrder.desc
  }
};
export const VISIBLE_SORT_OPTIONS = [DEFAULT_SORT_KEY, HIGH_PRICE_SORT_KEY];

const capAgg = (value: string): string => capitalize(value);

export const TERM_FILTERS_PREFIX = 'ft';
export const BRAND_AGGREGATION_FIELD = 'brand.title';
export const CATEGORIES_AGGREGATION_FIELD = 'categories.title';
export const CATEGORY_GROUP_PARAM = 'group';
export const CATEGORY_ROBOTS_ENTITY_ID = 'ROBOT';

export const AVAILABLE_AGGREGATIONS = [
  {
    title: 'Hoofdcategorie',
    field: CATEGORIES_AGGREGATION_FIELD,
    type: AggregationType.term,
    displayFormatter: capAgg,
    minCollapsibleWhenSelected: 1
  },
  {
    title: 'Subcategorie',
    field: 'subCategories.title',
    type: AggregationType.term,
    displayFormatter: capAgg
  },
  {
    title: 'Merk',
    field: BRAND_AGGREGATION_FIELD,
    type: AggregationType.term,
    displayFormatter: capAgg
  },
  {
    title: 'Aandrijving',
    field: 'powerSource.title',
    type: AggregationType.term,
    displayFormatter: capAgg
  },
  {
    title: 'Prijs',
    field: 'price',
    type: AggregationType.range,
    min: 0,
    max: 2500,
    step: 10,
    displayFormatter: (value: number): string =>
      `${getFormattedPrice(value)}${value === 2500 ? '+' : ''}`
  },
  {
    title: 'Werkbreedte',
    field: 'workWidth.title',
    type: AggregationType.term,
    displayFormatter: capAgg
  },
  {
    title: 'Uitvoering',
    field: 'build.title',
    type: AggregationType.term,
    displayFormatter: capAgg
  },
  {
    title: 'Machineland score',
    field: 'rating.title',
    type: AggregationType.term,
    sort: (a, b) => b.title.localeCompare(a.title)
  },

  {
    title: 'Elektrostart',
    field: 'electricStart.title',
    type: AggregationType.term,
    displayFormatter: capAgg
  },
  {
    title: 'Type',
    field: 'type.title',
    type: AggregationType.term,
    displayFormatter: capAgg
  },
  {
    title: 'Model',
    field: 'model.title',
    type: AggregationType.term,
    displayFormatter: capAgg
  },
  {
    title: 'Grasverwerking',
    field: 'grassProcessing.title',
    type: AggregationType.term,
    displayFormatter: capAgg
  }
];

export const SLUG_SHOPPING_CARD_PAGE = '/shopping-cart';
export const SLUG_CHECKOUT_PAGE = '/checkout';
export const SLUG_CHECKOUT_PAYMENT_METHOD_PAGE = '/checkout/payment-method';
export const SLUG_CHECKOUT_FINISH_PAGE = '/checkout/finish';

export const TOTAL_LOCATIONS = 5;

export const FREE_SHIPPING_THRESHOLD = 800;

export const FORM_REQUIRED = 'Verplicht';
export const INVALID_EMAIL = 'Ongeldig email adres';
export const SEARCH_PLACEHOLDER = 'Zoeken op product, categorie,...';

export enum browserSortBy {
  lowPrice = 'Prijs oplopend',
  highPrice = 'Prijs aflopend',
  relevant = 'Relevantie'
}

export const PRODUCT_IMAGES_MAIN_WIDTH = 600;
export const PRODUCT_IMAGES_MAIN_HEIGHT = 295;
export const BANNER_IMAGES_MOBILE_HEIGHT = 150;
export const BANNER_IMAGES_DESKTOP_HEIGHT = 120;

export const TIMEOUT_ON_APP_INIT_FOR_DELAYED_EVENTS = 10000;

export const LOGO_SVG = '/images/logo.svg';
export const NO_IMAGE_FILENAME = '/images/missing.jpg';
export const NO_IMAGE_SMALL_FILENAME = '/images/missing_small.jpg';

export const CATEGORY_ITEM_MENU: MenuItem = { to: '/categories', text: 'Hoofdcategorieën' };
export const COMMON_MENU_ITEMS: MenuItem[] = [
  { to: '/clearance', text: opOpTitle },
  { to: '/account/extra-warranty/', text: 'Extra garantie' },
  { to: '/brands', text: 'Merken' },
  { to: '/vacancies', text: 'Vacatures' },
  { to: '/support/contact/', text: 'Contact' },
  { to: '/service', text: 'Service' }
];
export const CATEGORY_LIST_SEO = [
  { title: 'Gras en Grasonderhoud', slug: 'gras-en-grasonderhoud', image: '' },
  { title: 'Snoeien en Zagen', slug: 'snoeien-en-zagen', image: '' },
  { title: 'Reiniging', slug: 'reiniging', image: '' },
  { title: 'Bouw en Industrie', slug: 'bouw-en-industrie', image: '' },
  { title: 'Accu machines', slug: 'accu-machines', image: '' },
  { title: 'Professioneel Materiaal', slug: 'professioneel-materiaal', image: '' },
  { title: 'Transport', slug: 'transport', image: '' },
  { title: 'Grasrobots', slug: 'grasrobots', image: '' }
];

export const EU_VAT_REG_EXP = {
  BE: {
    name: 'België',
    jsVatCountry: belgium,
    regExpTyping: /^BE(0?|0\d{0,9})$/
  },
  NL: {
    name: 'Nederland',
    jsVatCountry: netherlands,
    regExpTyping: /^NL(\d{0,9}|\d{9}B\d{0,2})$/
  }
};

export const DEFAULT_ADDRESS: Address = {
  address: '',
  company: '',
  country: DEFAULT_COUNTRY,
  email: '',
  firstName: '',
  lastName: '',
  locality: '',
  phoneNumber: '',
  postalCode: '',
  province: '',
  vatNumber: ''
};

export const SKELETON_LIST_ITEMS = [1, 2, 3, 4];

export const CONTACT_FORM_SERVICE_TYPE_GET_PARAMETER = 'type';
