import { type Address } from '@hypercharge/machineland-commons/lib/types/common';
import { type User } from '@hypercharge/machineland-commons/lib/types/user';
import React, {
  createContext,
  type PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { fetchMyUser, updateUserProfile } from '../../actions/user';
import { Status } from '../../utils/types';
import { useAuth } from '../auth/AuthProvider';
import { DEFAULT_COUNTRY } from '@hypercharge/machineland-commons/lib/constants';

type ContextValue = {
  user?: User;
  status: Status;
  updateProfile: (
    firstName: string,
    lastName: string,
    email: string,
    billingAddress: Address,
    shippingAddress: Address
  ) => Promise<void>;
};

const UserContext = createContext<ContextValue | undefined>(undefined);

const UserProvider = ({ children }: PropsWithChildren) => {
  const [status, setStatus] = useState(Status.Loading);
  const [user, setUser] = useState<User | undefined>(undefined);
  const cancelUserRequest = useRef(() => {});
  const { isAuthenticated, userId } = useAuth();

  const updateProfile = useCallback(
    async (
      firstName: string,
      lastName: string,
      email: string,
      billingAddress: Address,
      shippingAddress: Address
    ) => {
      try {
        await updateUserProfile(firstName, lastName, email, billingAddress, shippingAddress)
          .promise;
        setUser({
          ...(user as User),
          firstName,
          lastName,
          emailWork: email,
          // we don't really care about the id. No point returning the
          // address from the server we have just sent
          billingAddress: { entityId: '', ...billingAddress },
          shippingAddress: { entityId: '', ...shippingAddress }
        });
      } catch (e: unknown) {
        throw new Error('Failed to update profile');
      }
    },
    [user]
  );

  const fetchUser = useCallback(async () => {
    if (isAuthenticated && userId) {
      setStatus(Status.Loading);
      try {
        // Add setTimeout() for fix issue when AuthProvider storeAuthData(authData) in LocalStorage not get in time and are empty
        // TODO need investigate for catch this
        const { promise, abort } = await new Promise(resolve => setTimeout(resolve, 0)).then(() =>
          fetchMyUser()
        );

        // cancel any running request
        cancelUserRequest.current();
        cancelUserRequest.current = abort;

        const fetchedUser = await promise;

        // user exists in system
        if (fetchedUser !== null) {
          // since the UI is now using uppercase country selector
          // this allows us to keep retro compatibility
          setUser({
            ...fetchedUser,
            billingAddress: fetchedUser.billingAddress
              ? {
                  ...fetchedUser.billingAddress,
                  country: fetchedUser.billingAddress.country?.toUpperCase() || DEFAULT_COUNTRY
                }
              : undefined,
            shippingAddress: fetchedUser.shippingAddress
              ? {
                  ...fetchedUser.shippingAddress,
                  country: fetchedUser.shippingAddress.country?.toUpperCase() || DEFAULT_COUNTRY
                }
              : undefined
          });

          setStatus(Status.Success);
        } else {
          // user is not created yet. Maybe the trigger got
          // delayed retry again as we know that with a userId
          // a user will eventually exist
          setTimeout(fetchUser, 5000);
        }
      } catch (e: unknown) {
        setStatus(Status.Error);
      }
    } else if (!userId) {
      setUser(undefined);
      setStatus(Status.Success);
    }
  }, [isAuthenticated, userId]);

  useEffect(() => {
    void fetchUser();

    return () => {
      cancelUserRequest.current();
    };
  }, [cancelUserRequest, fetchUser]);

  const contextObject = useMemo(
    () => ({ user, status, updateProfile }),
    [user, status, updateProfile]
  );

  return <UserContext.Provider value={contextObject}>{children}</UserContext.Provider>;
};

const useUser = (): ContextValue => {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error('useUser must be used within an UserProvider');
  }

  return context;
};

export { useUser, UserProvider };
