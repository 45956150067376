import React, { Component, PropsWithChildren, type ReactElement } from 'react';

type StateT = {
  hasError: boolean;
};

export default class ErrorBoundary extends Component<PropsWithChildren, StateT> {
  state = {
    hasError: false
  };

  static getDerivedStateFromError(): StateT {
    return { hasError: true };
  }

  componentDidCatch(error): void {
    window.Sentry.withScope(scope => {
      scope.setExtra('data', { stack: error.stack });
      window.Sentry.captureException(error);
    });
    console.error(error);
    this.setState({ hasError: true });
  }

  render(): ReactElement {
    if (this.state.hasError) {
      return <h1>Er is iets fout gegaan. Neem contact op met de ondersteuning</h1>;
    }

    return this.props.children as ReactElement;
  }
}
