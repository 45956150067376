import { ParsedQuery, ParsedUrl, ParseOptions, parseUrl, stringify } from 'query-string';
import React, { createContext, PropsWithChildren, useCallback, useContext, useMemo } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { history } from './history';
import omit from 'lodash/omit';

export const QUERY_OPTIONS: ParseOptions = {
  arrayFormat: 'bracket'
};

type SetQueryParamArg = {
  key: string;
  value: string | string[] | null;
};

type ContextValue = {
  query: ParsedQuery;
  setQueryParams: (...newParams: SetQueryParamArg[]) => void;
  removeQueryParams: (...keys: string[]) => void;
};

export const QueryContext = createContext<ContextValue | undefined>(undefined);

const parse = (path: string): ParsedUrl => parseUrl(path, QUERY_OPTIONS); // TODO use qs

export const UrlQueryProvider = withRouter(
  ({ location, ...otherProps }: PropsWithChildren<RouteComponentProps<any>>) => {
    const parsedUrl = useMemo(() => parse(location.search), [location.search]);

    // we want a function that accepts multiple params in order to change all needed at once
    // and thus avoid unecessary downstream renders
    const setQueryParams = useCallback(
      (...newParams: SetQueryParamArg[]) => {
        const newQuery = {
          ...parsedUrl.query
        };

        for (const p of newParams) {
          newQuery[p.key] = p.value;
        }
        history.push({
          search: stringify(newQuery, QUERY_OPTIONS)
        });
      },
      [parsedUrl.query]
    );

    const removeQueryParams = useCallback(
      (...keys: string[]) => {
        const newQuery = {
          ...parsedUrl.query
        };

        history.push({
          search: stringify(omit(newQuery, keys), QUERY_OPTIONS)
        });
      },
      [parsedUrl.query]
    );

    const value = useMemo(
      () => ({
        setQueryParams,
        query: parsedUrl.query,
        removeQueryParams
      }),
      [setQueryParams, parsedUrl, removeQueryParams]
    );

    return <QueryContext.Provider value={value} {...otherProps} />;
  }
);

export const useQuery = (): ContextValue => {
  const value = useContext(QueryContext);

  if (value === undefined) {
    throw new Error('useQuery must be used inside a QueryProvider');
  }

  return value;
};
