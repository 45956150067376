import { ProductLabel } from '@hypercharge/machineland-commons/lib/types/products';
import {
  ConditionQuery,
  ConditionType,
  Filter,
  FilterOperatorTypes
} from '@hypercharge/machineland-commons/lib/types/search';
import find from 'lodash/find';

import { CATEGORIES_AGGREGATION_FIELD } from '../../utils/constants';

export const getLabelsConditionQuery = (labels: ProductLabel[]): ConditionQuery => ({
  condition: ConditionType.or,
  filters: labels.map(label => ({
    field: 'labels.entityId',
    data: label,
    operator: FilterOperatorTypes.contains
  }))
});

// this is a hack to pass the value to the
// aggregation service that expects {start, end} data
const convertBetweenToFilters = (filters: Filter[]): Filter[] => {
  const newFilters: Filter[] = [];

  filters.forEach(f => {
    if (f.operator === FilterOperatorTypes.between) {
      if (f.data.start || f.data.start === 0) {
        newFilters.push({
          operator: FilterOperatorTypes.greaterThanOrEqual,
          data: f.data.start,
          field: f.field
        });
      }
      if (f.data.end || f.data.end === 0) {
        newFilters.push({
          operator: FilterOperatorTypes.lessThanEqual,
          data: f.data.end,
          field: f.field
        });
      }
    } else {
      newFilters.push(f);
    }
  });

  return newFilters;
};

export const isNotMiscellaneousQuery: ConditionQuery = {
  condition: ConditionType.and,
  filters: [
    {
      operator: FilterOperatorTypes.notEquals,
      data: true,
      field: 'isMiscellaneous'
    }
  ]
};

export const masterProductQuery: ConditionQuery = {
  condition: ConditionType.or,
  filters: [
    {
      operator: FilterOperatorTypes.equals,
      data: true,
      field: 'masterProduct'
    },
    {
      operator: FilterOperatorTypes.empty,
      field: 'variants'
    }
  ]
};

export const getGroupedQueries = (queries: Array<Filter | ConditionQuery>): ConditionQuery => ({
  condition: ConditionType.and,
  filters: queries
});

export const getConditionQueryWithLabels = (
  facetCondition?: ConditionQuery,
  labels?: ProductLabel[]
): ConditionQuery => {
  const newFacetCondition = facetCondition
    ? {
        ...facetCondition,
        filters: convertBetweenToFilters(facetCondition.filters as Filter[])
      }
    : facetCondition;

  if (!labels) {
    return getGroupedQueries(
      newFacetCondition
        ? [isNotMiscellaneousQuery, masterProductQuery, newFacetCondition]
        : [isNotMiscellaneousQuery, masterProductQuery]
    );
  }

  const labelConditionQuery = getLabelsConditionQuery(labels);

  return getGroupedQueries(
    newFacetCondition
      ? [isNotMiscellaneousQuery, masterProductQuery, newFacetCondition, labelConditionQuery]
      : [isNotMiscellaneousQuery, masterProductQuery, labelConditionQuery]
  );
};

// the client wants to also show robotmaaiers (robot lawnmowers)
// when grasmaaiers (lawnmowers) is selected
// leaving this as a "generic" place to inject more upselling if needed
export const getFiltersWithUpselling = (field: string, filters: Filter[]): Filter[] => {
  if (field === CATEGORIES_AGGREGATION_FIELD) {
    const hasLawnmowers = find(filters, {
      data: 'grasmaaiers',
      field: CATEGORIES_AGGREGATION_FIELD
    });

    return hasLawnmowers
      ? [
          ...filters,
          {
            data: 'robotmaaiers',
            field: CATEGORIES_AGGREGATION_FIELD,
            operator: FilterOperatorTypes.is
          }
        ]
      : filters;
  } else {
    return filters;
  }
};
