import { getFormattedPrice } from '@hypercharge/machineland-commons/lib/utils/price';
import React, { FC } from 'react';

type PropsT = {
  price: number;
  previousPrice?: number;
  classNames: { currentPrice: string; previousPrice: string };
  withMetaData?: boolean;
};

const Price: FC<PropsT> = ({ price, previousPrice, withMetaData = true, classNames }) => {
  return (
    <div
      {...(withMetaData
        ? { itemProp: 'offers', itemScope: true, itemType: 'http://schema.org/Offer' }
        : {})}
    >
      {withMetaData && (
        <>
          <meta itemProp="price" content={`${price || ''}`} />

          <meta itemProp="priceCurrency" content="EUR" />
        </>
      )}

      <span className={classNames.currentPrice}>{getFormattedPrice(price)}</span>

      {!!previousPrice && (
        <span className={classNames.previousPrice}>{getFormattedPrice(previousPrice)}</span>
      )}
    </div>
  );
};

export default Price;
