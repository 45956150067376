import { Product } from '@hypercharge/machineland-commons/lib/types/products';
import { PromoCode } from '@hypercharge/machineland-commons/lib/types/promo';
import {
  ShoppingCartItem,
  StoredShoppingCartItem
} from '@hypercharge/machineland-commons/lib/types/shopping-cart';
import {
  getFormattedPrice,
  getPriceWithPromoCode,
  isPaidTransportRequired
} from '@hypercharge/machineland-commons/lib/utils/price';
import { Decimal } from 'decimal.js';
import debounce from 'lodash/debounce';
import find from 'lodash/find';
import map from 'lodash/map';

import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { recordAddToCart, recordRemoveFromCart } from '../../actions/analytics';
import {
  findProductsByEntityId,
  getMontageProducts,
  getTransportProduct
} from '../../actions/products';
import { fetchPromoCode } from '../../actions/promo';
import { showError, showInfo, showSuccess } from '../../utils/notifications';
import { loadFromLocalStorage, saveInLocalStorage } from '../../utils/storage';
import { Status } from '../../utils/types';
import { useAuth } from '../auth/AuthProvider';
import { OrderDetails } from 'types/orders';
import { ShippingMethod } from '@hypercharge/machineland-commons/lib/types/orders';
import { getAvailableProvinces } from '@hypercharge/machineland-commons/lib/utils/address';
import { FREE_SHIPPING_THRESHOLD } from '../../utils/constants';

export type ShoppingCartContextT = {
  addToCart: (
    product: Product,
    quantity?: number,
    successNotify?: boolean,
    notifyInfoMessage?: string
  ) => void;
  applyPromoCode: (code: string) => Promise<void>;
  cartItems: ShoppingCartItem[];
  freeMontageItem: Product | undefined;
  getItemCount: () => number;
  loaded: boolean;
  paidMontageItem: Product | undefined;
  promoCode?: PromoCode;
  removeFromCart: (product: Product, successNotify?: boolean, notifyInfoMessage?: string) => void;
  removePromoCode: () => void;
  resetCart: () => void;
  total?: Decimal;
  totalCostWithoutMiscellaneous: Decimal;
  toggleMontageItemToCart: (values: OrderDetails) => void;
  toggleTransportItemToCart: (values: OrderDetails) => void;
  transportItem: Product | undefined;
  isTransportItemAdded: boolean;
  updateQuantity: (productId: string, quantity: number) => void;
};

const storageKey = 'shoppingCart';

const debouncedSuccess = debounce(showSuccess, 500, { leading: true });

const ShoppingCartContext = createContext<ShoppingCartContextT | undefined>(undefined);

const ShoppingCartProvider = ({ children }: PropsWithChildren) => {
  const [cartItems, setCartItems] = useState<ShoppingCartItem[]>([]);
  const [status, setStatus] = useState(Status.Idle);
  const [promoCode, setPromoCode] = useState<PromoCode>();
  const [freeMontageItem, setFreeMontageItem] = useState<Product>();
  const [paidMontageItem, setPaidMontageItem] = useState<Product>();
  const [transportItem, setTransportItem] = useState<Product>();

  const [total, setTotal] = useState<Decimal>();
  const { isAuthenticated } = useAuth();

  // ? If a user added a `paid montage` from the `shopping cart` page
  // ? and on the `checkout` page the item was removed due to an unavailable province,
  // ? but later the user changed the region to be available for a montage service,
  // ? the `paid montage` must be added again to cart.
  const [paidMontageMustBeAdded, setPaidMontageMustBeAdded] = useState<boolean>(false);

  const isTransportItemAdded = useMemo(
    () =>
      !!transportItem &&
      cartItems.some(cartItem => cartItem.item?.entityId === transportItem.entityId),
    [cartItems, transportItem]
  );

  const addedMontageItemToCart = useMemo(
    () => cartItems.find(cartItem => cartItem.item?.isMontage),
    [cartItems]
  );

  useEffect(() => {
    void getMontageProducts().then(items => {
      items.forEach(item => {
        item.price > 1 ? setPaidMontageItem(item) : setFreeMontageItem(item);
      });
    });

    void getTransportProduct().then(item => {
      setTransportItem(item);
    });
  }, []);

  const syncStorage = (cartItems: ShoppingCartItem[]) => {
    saveInLocalStorage(
      storageKey,
      cartItems.map(item => ({ id: item.id, quantity: item.quantity }))
    );
  };

  const resetCart = useCallback(() => {
    setCartItems([]);
    syncStorage([]);
    setPromoCode(undefined);
  }, []);

  const totalCostWithoutMiscellaneous = useMemo(
    () =>
      cartItems.reduce((acc, cur) => {
        if (!cur.item || cur.item.isMiscellaneous) {
          return acc;
        }

        return acc.add(new Decimal(cur.item.price).times(cur.quantity));
      }, new Decimal(0)),
    [cartItems]
  );

  const updateProductQuantity = useCallback(
    (productId: string, quantity: number, notify = true) => {
      const newCartItems = map(cartItems, elem => {
        if (elem.id === productId && elem.item && !elem.item.isMiscellaneous) {
          if (quantity > elem.quantity) {
            recordAddToCart(elem.item, quantity - elem.quantity);
          } else if (quantity < elem.quantity) {
            recordRemoveFromCart(elem.item, elem.quantity - quantity);
          }

          return { ...elem, quantity };
        }

        return elem;
      });

      setCartItems(newCartItems);
      syncStorage(newCartItems);
      notify && debouncedSuccess('Producthoeveelheid bijgewerkt');
    },
    [cartItems]
  );

  const addProductToCart = useCallback(
    (newProduct: Product, quantity = 1, successNotify = true, notifyInfoMessage?: string) => {
      const existing = find(cartItems, ({ id }) => id === newProduct.entityId);

      if (existing) {
        updateProductQuantity(newProduct.entityId, existing.quantity + quantity, successNotify);
      } else {
        const newCartItems = [
          ...cartItems,
          { id: newProduct.entityId, item: newProduct, quantity }
        ];

        setCartItems(newCartItems);
        syncStorage(newCartItems);

        successNotify && showSuccess('Product toegevoegd aan het winkelwagentje');
        notifyInfoMessage && showInfo(notifyInfoMessage);

        !newProduct.isMiscellaneous && recordAddToCart(newProduct, quantity);
      }
    },
    [cartItems, updateProductQuantity]
  );

  const removeProductFromCart = useCallback(
    (product: Product, successNotify = true, notifyInfoMessage?: string) => {
      const existing = find(cartItems, ({ id }) => id === product.entityId);

      if (existing) {
        !product.isMiscellaneous && recordRemoveFromCart(product, existing.quantity);

        const newCartItems = cartItems.filter(elem => elem.item?.entityId !== product.entityId);

        setCartItems(newCartItems);
        syncStorage(newCartItems);

        successNotify && showSuccess('Product verwijderd');
        notifyInfoMessage && showInfo(notifyInfoMessage);
      }
    },
    [cartItems]
  );

  const getItemCount = useCallback(
    () => cartItems.reduce((count, { quantity }) => count + (quantity || 0), 0),
    [cartItems]
  );

  const fetchCartItems = useCallback(async (items: ShoppingCartItem[]) => {
    try {
      const products = await findProductsByEntityId(items.map(item => item.id));
      const newCartItems: ShoppingCartItem[] = items.reduce<ShoppingCartItem[]>((acc, item) => {
        const product = find(products, ({ entityId }) => entityId === item.id);

        if (product) {
          acc.push({
            id: item.id,
            item: product,
            quantity: item.quantity
          });
        }

        return acc;
      }, []);

      setCartItems(newCartItems);
      syncStorage(newCartItems);

      setStatus(Status.Success);
    } catch (e: unknown) {
      console.error(e);
    }
  }, []);

  const fetchData = useCallback(async () => {
    const storedItems: StoredShoppingCartItem[] | undefined = loadFromLocalStorage(storageKey);

    setStatus(Status.Loading);

    if (storedItems) {
      setCartItems(storedItems);
      storedItems.length > 0 && (await fetchCartItems(storedItems));
    } else {
      setStatus(Status.Success);
    }
  }, [fetchCartItems]);

  const applyPromoCode = useCallback(
    async (code: string) => {
      if (code) {
        const promoCode = await fetchPromoCode(code, isAuthenticated).promise;

        if (promoCode) {
          if (
            !promoCode.minimumOrderValue ||
            (promoCode.minimumOrderValue &&
              total instanceof Decimal &&
              promoCode.minimumOrderValue <= total.toNumber())
          ) {
            setPromoCode(promoCode);
            showSuccess('Promotiecode toegepast');
          } else {
            showError(
              `Promotiecode alleen geldig voor bovenstaande bestelling ${getFormattedPrice(
                promoCode.minimumOrderValue
              )}`
            );
          }
        } else {
          showError('Ongeldige promotiecode');
        }
      } else {
        setPromoCode(undefined);
      }
    },
    [isAuthenticated, total]
  );

  const removePromoCode = useCallback(() => {
    setPromoCode(undefined);
  }, []);

  useEffect(() => {
    let total = new Decimal(0);

    for (let i = 0; i < cartItems.length; i++) {
      const { item, quantity } = cartItems[i];

      if (item && (item.price || item.isMiscellaneous)) {
        total = total.plus(new Decimal(item.price).times(quantity));
      } else {
        // if the total is 0 then not all products are loaded
        total = new Decimal(0);
      }
    }

    if (promoCode) {
      total = getPriceWithPromoCode(total, promoCode);
    }

    setTotal(total);
  }, [cartItems, promoCode]);

  useEffect(() => {
    void fetchData();
  }, [fetchData]);

  const toggleTransportItemToCart = useCallback(
    (values: OrderDetails) => {
      if (transportItem && status === Status.Success) {
        if (
          values.shippingMethod === ShippingMethod.home &&
          isPaidTransportRequired(totalCostWithoutMiscellaneous.toNumber())
        ) {
          if (!isTransportItemAdded) {
            addProductToCart(transportItem, 1, false);
          }
        } else {
          if (isTransportItemAdded) {
            removeProductFromCart(transportItem, false);
          }
        }
      }
    },
    [
      addProductToCart,
      removeProductFromCart,
      status,
      totalCostWithoutMiscellaneous,
      transportItem,
      isTransportItemAdded
    ]
  );

  const toggleMontageItemToCart = useCallback(
    (values: OrderDetails) => {
      const shippingAddress = values.sameAddress ? values.billingAddress : values.shippingAddress;
      const totalCostWithoutMiscellaneousInNumber = totalCostWithoutMiscellaneous.toNumber();

      const provinceIsUnavailableForMontageService =
        addedMontageItemToCart &&
        (shippingAddress.country === 'NL' ||
          !shippingAddress.province ||
          !getAvailableProvinces().includes(shippingAddress.province));

      const provinceIsAvailableForMontageService =
        !addedMontageItemToCart &&
        shippingAddress.country === 'BE' &&
        shippingAddress.province &&
        getAvailableProvinces().includes(shippingAddress.province);

      if (provinceIsUnavailableForMontageService) {
        if (addedMontageItemToCart?.item && addedMontageItemToCart?.item?.price > 1) {
          setPaidMontageMustBeAdded(true);
        }

        addedMontageItemToCart?.item &&
          removeProductFromCart(
            addedMontageItemToCart.item,
            false,
            `"${addedMontageItemToCart.item.title}" is niet beschikbaar voor deze regio. Extra montage is uit uw winkelmand verwijderd.`
          );
      }

      if (provinceIsAvailableForMontageService) {
        if (paidMontageMustBeAdded && paidMontageItem) {
          addProductToCart(
            paidMontageItem,
            1,
            false,
            `"${paidMontageItem.title}" is beschikbaar voor deze regio, het werd toegepast op uw bestelling.`
          );
        }

        if (totalCostWithoutMiscellaneousInNumber >= FREE_SHIPPING_THRESHOLD && freeMontageItem) {
          addProductToCart(
            freeMontageItem,
            1,
            false,
            `"${freeMontageItem.title}" is beschikbaar voor deze regio, het werd toegepast op uw bestelling.`
          );
        }
      }
    },
    [
      addProductToCart,
      freeMontageItem,
      addedMontageItemToCart,
      paidMontageItem,
      paidMontageMustBeAdded,
      removeProductFromCart,
      totalCostWithoutMiscellaneous
    ]
  );

  const contextObject = useMemo(
    () => ({
      addToCart: addProductToCart,
      applyPromoCode,
      cartItems,
      freeMontageItem,
      getItemCount,
      loaded: status === Status.Success,
      paidMontageItem,
      promoCode,
      removeFromCart: removeProductFromCart,
      removePromoCode,
      resetCart,
      toggleMontageItemToCart,
      toggleTransportItemToCart,
      total,
      totalCostWithoutMiscellaneous,
      transportItem,
      isTransportItemAdded,
      updateQuantity: updateProductQuantity
    }),
    [
      addProductToCart,
      applyPromoCode,
      cartItems,
      freeMontageItem,
      getItemCount,
      paidMontageItem,
      promoCode,
      removeProductFromCart,
      removePromoCode,
      resetCart,
      status,
      toggleMontageItemToCart,
      toggleTransportItemToCart,
      total,
      totalCostWithoutMiscellaneous,
      transportItem,
      isTransportItemAdded,
      updateProductQuantity
    ]
  );

  return (
    <ShoppingCartContext.Provider value={contextObject}>{children}</ShoppingCartContext.Provider>
  );
};

const useShoppingCart = (): ShoppingCartContextT => {
  const context = useContext(ShoppingCartContext);

  if (context === undefined) {
    throw new Error('useShoppingCart must be used within an ShoppingCartProvider');
  }

  return context;
};

export { useShoppingCart, ShoppingCartProvider };
