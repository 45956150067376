import Tooltip from 'antd/es/tooltip';
import { FaInfoCircle } from '@react-icons/all-files/fa/FaInfoCircle';
import IconStar from '@ant-design/icons/es/icons/StarOutlined';
import times from 'lodash/times';
import React, { FC, Fragment } from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

const Rating = styled.div`
  line-height: 16px;
  height: 16px;

  > * {
    display: inline-block;
    vertical-align: middle;
    font-weight: 500;
  }

  @media print {
    text-align: left;
    padding: 5px 0;
  }
`;

const Description = styled.span`
  margin-right: 0.5rem;
`;

const Stars = styled.div`
  height: 16px;

  span {
    display: none;
  }

  svg {
    color: #eee;
    width: 16px;
    height: 16px;
  }

  .selected svg {
    color: #f6c343;
  }
`;

const TooltipPopupWindow = styled.div`
  .ant-tooltip-inner {
    padding: 20px;
  }
`;

type PropsT = {
  value: number;
  withDescription?: boolean;
  className?: string;
  withMetaData?: boolean;
};

const RatingComponent: FC<PropsT> = ({
  value,
  withDescription,
  withMetaData = true,
  className
}) => (
  <Rating className={className}>
    {withDescription && <Description>Machineland score</Description>}
    <Stars className={`stars star${value}`} title={`Waardering: ${value} / 5`}>
      {withMetaData && !!value && (
        <div itemProp="aggregateRating" itemScope itemType="http://schema.org/AggregateRating">
          <meta itemProp="ratingValue" content={`${value}`} />
          <meta itemProp="ratingCount" content="1" />
          <meta itemProp="bestRating" content="5" />
          <meta itemProp="worstRating" content="0" />
        </div>
      )}
      {times(5, i => (
        <IconStar key={i} theme="filled" className={i < value ? 'selected' : undefined} />
      ))}
    </Stars>{' '}
    <Tooltip
      title={
        <Fragment>
          De sterren geven een beoordeling weer van de verhouding prijs/kwaliteit. <br />
          <Link
            to="/support/star-rating"
            style={{
              color: 'white',
              textDecoration: 'underline',
              fontWeight: 400
            }}
          >
            Meer info over de sterrenbeoordeling
          </Link>
        </Fragment>
      }
      getPopupContainer={triggerNode => triggerNode}
    >
      <TooltipPopupWindow>
        <FaInfoCircle style={{ color: 'lightgrey' }} />
      </TooltipPopupWindow>
    </Tooltip>
  </Rating>
);

export default RatingComponent;
