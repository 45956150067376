import { Product as ProductT } from '@hypercharge/machineland-commons/lib/types/products';
import React, { FC, MouseEvent, useCallback, useState } from 'react';
import Button from 'antd/es/button';
import { MdAddShoppingCart } from '@react-icons/all-files/md/MdAddShoppingCart';
import { useShoppingCart } from '../context/shopping-cart/ShoppingCartProvider';
import { useSuggestedProducts } from '../context/shopping-cart/SuggestedProductsProvider';
import style from './AddToCart.module.scss';

type PropsT = {
  product: ProductT;
  withText?: boolean;
  className?: string;
  disabled?: boolean;
  onClick?: (product: ProductT) => void;
};

const preventPropagation = (e: MouseEvent<HTMLElement>) => {
  e.stopPropagation();
  e.preventDefault();
};

const AddToCart: FC<PropsT> = ({ withText, product, onClick, className, disabled }) => {
  const [quantity] = useState(1);
  const { addToCart } = useShoppingCart();
  const { openSuggestions } = useSuggestedProducts();

  const addProductToCart = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      addToCart(product, quantity, false);
      openSuggestions(product.slug);
    },
    [addToCart, product, quantity, openSuggestions]
  );

  return (
    <div className={`${style.addToCart} ${className || ''}`} onClick={preventPropagation}>
      <div>
        <div>
          <Button
            type="primary"
            onClick={
              onClick
                ? () => {
                    onClick(product);
                  }
                : addProductToCart
            }
            className="button"
            disabled={disabled}
          >
            <MdAddShoppingCart className="shoppingCartIcon" />
            {withText && <span className="textWrapper">Toevoegen aan winkelmandje</span>}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddToCart;
