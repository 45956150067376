import { loadFromLocalStorage, saveInLocalStorage } from '../../utils/storage';

const storageKey = '__auth__';

export type AuthData = {
  idToken: string;
  expiresAt: number;
  accessToken: string;
  userId?: string;
  refreshToken: string;
};

export const getStoredAuthData = (): AuthData | undefined => loadFromLocalStorage(storageKey);

export const storeAuthData = (authData: AuthData | undefined) => {
  saveInLocalStorage(storageKey, authData);
};

export const isTokenValid = (jwtExpiry: number): boolean => {
  const now = new Date().getTime();

  return !!jwtExpiry && jwtExpiry - now > 0;
};

export const buildBearerContent = (idToken: string) => `Bearer ${idToken}`;
