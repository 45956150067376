"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isPaidTransportRequired = exports.getPriceWithPromoCode = exports.getFormattedPrice = void 0;
var decimal_js_1 = require("decimal.js");
var constants_1 = require("../constants");
var hasDecimals = function (value) { return value % 1 !== 0; };
var getFormattedPrice = function (value, currency, precision, removeTrailingZeros) {
    if (currency === void 0) { currency = 'EUR'; }
    if (precision === void 0) { precision = 2; }
    if (removeTrailingZeros === void 0) { removeTrailingZeros = false; }
    if (value != null) {
        var newPrecision = removeTrailingZeros && !hasDecimals(value) ? 0 : precision;
        var formatted = value.toLocaleString([], {
            style: 'currency',
            currency: currency,
            minimumFractionDigits: newPrecision,
            maximumFractionDigits: newPrecision
        });
        return formatted.indexOf('\xa0') === -1
            ? formatted.replace(/(.+?)(\d+.*)/, '$1\xa0$2')
            : formatted;
    }
    else {
        return '';
    }
};
exports.getFormattedPrice = getFormattedPrice;
var getPriceWithPromoCode = function (value, promoCode) {
    var total = new decimal_js_1.Decimal(value);
    if (promoCode.amountDiscount) {
        total = total.minus(promoCode.amountDiscount);
    }
    else if (promoCode.percentDiscount) {
        total = total.times(new decimal_js_1.Decimal(1).minus(new decimal_js_1.Decimal(promoCode.percentDiscount).dividedBy(100)));
    }
    if (total.isNegative()) {
        return new decimal_js_1.Decimal(0);
    }
    return new decimal_js_1.Decimal(total);
};
exports.getPriceWithPromoCode = getPriceWithPromoCode;
var isPaidTransportRequired = function (total) { return total < constants_1.TRANSPORT_COST_THRESHOLD; };
exports.isPaidTransportRequired = isPaidTransportRequired;
