import * as Yup from 'yup';

declare global {
  interface Window {
    dataLayer: {
      push: (obj: any) => void;
    };
    Sentry: {
      captureException: (error: unknown) => void;
      init: (options: any) => void;
      withScope: (scope: any) => void;
    };
    fbq?: any;
    _fbq?: any;
  }
}

export const injectExtraValidators = () => {
  Yup.addMethod(Yup.string, 'vat', function () {
    return this.test(
      'vat',
      'Ongeldig btw-nummer',
      value => !value || /^(NL[0-9]{9}B[0-9]{2})|(BE[0-9]{10})$/.test(value)
    );
  });
};

export const getErrorJSON = (error: Error) => {
  const alt = {};

  Object.getOwnPropertyNames(error).forEach(function (key) {
    alt[key] = error[key];
  }, error);

  return JSON.stringify(alt);
};
