import './index.css';
import React, { FC, useMemo, useState, lazy, Suspense } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { injectExtraValidators } from './globals';
import {
  getBrandFilterQueryString,
  getCategoryFilterQueryString,
  getDescriptionQueryForCustomSearchBySlug
} from './utils/search';
import {
  isBlackFridayDateRange,
  SLUG_CHECKOUT_FINISH_PAGE,
  SLUG_CHECKOUT_PAGE,
  SLUG_CHECKOUT_PAYMENT_METHOD_PAGE,
  SLUG_SHOPPING_CARD_PAGE
} from './utils/constants';
import { useDescriptionsProductFilters } from './context/secondaryUseQueryProviders';
import LoadingDots from './components/LoadingDots';
import SplashPage from './pages/SplashPage';

const Loading = lazy(async () => await import('./components/Loading'));
const Layout = lazy(async () => await import('./layout/Layout'));
const NotFound = lazy(async () => await import('./layout/NotFound'));
const About = lazy(async () => await import('./pages/About'));
const Account = lazy(async () => await import('./pages/account'));
const Antwerpen = lazy(async () => await import('./pages/Antwerpen'));
const Callback = lazy(async () => await import('./pages/auth/Callback'));
const Categories = lazy(async () => await import('./pages/Categories'));
const CategoryGroups = lazy(async () => await import('./pages/CategoryGroups'));
const Checkout = lazy(async () => await import('./pages/checkout'));
const Finish = lazy(async () => await import('./pages/checkout/Finish'));
const PaymentMethod = lazy(async () => await import('./pages/checkout/PaymentMethod'));
const Clearance = lazy(async () => await import('./pages/Clearance'));
const Contact = lazy(async () => await import('./pages/Contact'));
const MainPage = lazy(async () => await import('./pages/MainPage'));
const ProductDetail = lazy(async () => await import('./pages/ProductDetail'));
const ProfMaterial = lazy(async () => await import('./pages/ProfMaterial'));
const Search = lazy(async () => await import('./pages/Search'));
const ShoppingCart = lazy(async () => await import('./pages/ShoppingCart'));
const SupportPage = lazy(async () => await import('./pages/support'));
const TermsAndConditions = lazy(async () => await import('./pages/TermsAndConditions'));
const Vacancies = lazy(async () => await import('./pages/vacancies/Vacancies'));
const VacancyDetails = lazy(async () => await import('./pages/VacancyDetails'));
const Brands = lazy(async () => await import('./pages/Brands'));
const ServiceForm = lazy(async () => await import('./pages/ServiceForm'));

window.Sentry = {
  init: () => {},
  withScope: () => {},
  captureException: () => {}
};

window.dataLayer = [{}];

const ProductDetailWrapper = ({ match: { params } }) => {
  return useMemo(() => <ProductDetail slug={params.productSlug} />, [params.productSlug]);
};

const CategoriesWrapper = ({ match: { params } }) => {
  return useMemo(
    () => <Categories categoryGroupSlug={params.categoryGroupSlug} />,
    [params.categoryGroupSlug]
  );
};

const RedirectToCategorySearch = ({
  match: {
    params: { categoryGroupSlug, categoryTitle }
  },
  location: { search }
}: {
  match: {
    params: { categoryGroupSlug: string; categoryTitle: string };
  };
  location: { search: string };
}) => {
  return (
    <Redirect
      to={`/search${search ? search + '&' : '?'}${getCategoryFilterQueryString(
        categoryTitle,
        categoryGroupSlug
      )}`}
    />
  );
};

const RedirectToCustomSearch = ({
  match: {
    params: { slug }
  }
}) => {
  const { data: descriptions, isLoading } = useDescriptionsProductFilters();

  return isLoading || !descriptions ? (
    <Loading />
  ) : (
    <Redirect to={`/search?${getDescriptionQueryForCustomSearchBySlug(descriptions, slug)}`} />
  );
};

const RedirectToBrandSearch = ({
  match: {
    params: { brandTitle }
  },
  location: { search }
}: {
  match: {
    params: { brandTitle: string };
  };
  location: { search: string };
}) => {
  return (
    <Redirect
      to={`/search${search ? search + '&' : '?'}${getBrandFilterQueryString(brandTitle)}`}
    />
  );
};

injectExtraValidators();

const App: FC = () => {
  const [isBlackFriday] = useState(() => isBlackFridayDateRange);

  // If the last character of the url is '/' - remove trailing Slash
  const location = useLocation();
  const history = useHistory();

  if (location.pathname !== '/' && location.pathname.endsWith('/')) {
    const newPathname = location.pathname.slice(0, -1);

    history.replace(newPathname);
  }

  return (
    <>
      <Layout>
        <Suspense fallback={<LoadingDots />}>
          <Switch>
            <Route
              exact
              path="/"
              component={() => (isBlackFriday ? <Clearance /> : <MainPage />)}
            />
            <Route exact path="/search" component={Search} />
            <Route path="/lp/:slug*" component={RedirectToCustomSearch} />
            <Route exact path="/clearance" component={Clearance} />
            <Route exact path="/prof-materiaal" component={ProfMaterial} />
            <Route exact path="/about" component={About} />
            <Route exact path="/support/contact" component={Contact} />
            <Route exact path="/service" component={ServiceForm} />
            <Route path="/support" component={SupportPage} />
            <Route exact path="/empty-layout" component={SplashPage} />
            <Route path={SLUG_SHOPPING_CARD_PAGE} component={ShoppingCart} />
            <Route path={SLUG_CHECKOUT_PAYMENT_METHOD_PAGE} component={PaymentMethod} />
            <Route path={SLUG_CHECKOUT_FINISH_PAGE} component={Finish} />
            <Route path={SLUG_CHECKOUT_PAGE} exact component={Checkout} />
            <Route path="/auth/callback" component={Callback} />
            <Route path="/account" component={Account} />
            <Route exact path="/vacancies" component={Vacancies} />
            <Route exact path="/brands" component={Brands} />
            <Route path="/brands/:brandTitle" component={RedirectToBrandSearch} />
            <Route
              path="/categories/:categoryGroupSlug/:categoryTitle"
              component={RedirectToCategorySearch}
            />
            <Route path="/categories/:categoryGroupSlug" component={CategoriesWrapper} />
            <Route path="/categories" component={CategoryGroups} />
            <Route exact path="/terms-conditions" component={TermsAndConditions} />
            <Route exact path="/:productSlug" component={ProductDetailWrapper} />
            <Route exact path="/vacancies/:vacancySlug" component={VacancyDetails} />
            <Route exact path="/content/antwerpen" component={Antwerpen} />
            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </Layout>
    </>
  );
};

export default App;
