import styled from 'styled-components/macro';
import { AliasToken } from 'antd/es/theme/interface';

export const theme = {
  textColor: '#333',
  mainColor: '#02815f',
  mainColorActive: '#aaca38',
  alternativeColor: '#0B7F46',
  alternativeColorActive: '#aaca38',
  alternativeByDefault: true,
  mainColorSoft: '#e0f4ee',
  invalidColor: '#f44336',
  alarmColorHard: '#db9934',
  alarmColor: '#ffedd3',
  buttonBorderRadius: '4px',
  cardBoxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.15)',
  width: '1200px'
};
export const antdThemToken: Partial<AliasToken> = {
  fontSize: 16,
  fontFamily: 'Roboto, sans-serif',
  colorPrimary: theme.mainColor,
  colorLinkHover: theme.mainColorActive,
  colorLinkActive: theme.mainColorActive,
  colorHighlight: theme.alternativeColor,
  colorBgSpotlight: theme.mainColorActive,
  colorBgLayout: theme.mainColorActive
};

export const sizes: Record<string, number> = {
  sm: 568,
  md: 768,
  lg: 1024,
  wrapper: 1120,
  xl: 1280
};

export const media: Record<string, any> = Object.keys(sizes).reduce((acc: any, label: string) => {
  acc[label] = `@media screen and (min-width: ${sizes[label] / 16}em)`;

  return acc;
}, {});

// TODO refactoring this on SCSS

type ColumnPropsT = {
  colspan?: number;
  width?: string;
};

export const Column = styled.td<ColumnPropsT>`
  padding: 0.5rem;
  vertical-align: middle;
`;

export const Header = styled.thead`
  border-bottom: 1px solid #ececec;
  font-weight: bold;
`;

export const Table = styled.table`
  width: 100%;

  .center {
    text-align: center;
  }

  .right {
    text-align: right;
  }

  tr ${Column}:first-child {
    padding-left: 0;
  }

  tr ${Column}:last-child {
    padding-right: 0;
  }

  ${media.md} {
    tr ${Column}:first-child, tr ${Column}:last-child {
      padding: 0.5rem;
    }
  }
`;

export const Body = styled.tbody`
  tr {
    border-bottom: 1px solid #f6f6f6;

    &:hover {
      background: #f5f5f5;
    }
  }
`;
