import { buildBearerContent } from '../context/auth/utils';

export const openLoginPage = (returnOrigin: string) => {
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  window.location.href = `https://${process.env.REACT_APP_AUTH_DOMAIN}/login?response_type=code&client_id=${process.env.REACT_APP_AUTH_CLIENT_ID}&redirect_uri=${returnOrigin}/auth/callback`;
};

export const openLogoutPage = (returnOrigin: string) => {
  window.location.href = `https://${process.env.REACT_APP_AUTH_DOMAIN}/logout?client_id=${process.env.REACT_APP_AUTH_CLIENT_ID}&logout_uri=${returnOrigin}/auth/callback`;
};

const requestTokens = async (returnOrigin: string, code?: string, refreshToken?: string) => {
  let dataStr = `grant_type=${code ? 'authorization_code' : 'refresh_token'}&client_id=${
    process.env.REACT_APP_AUTH_CLIENT_ID
  }&redirect_uri=${returnOrigin}/auth/callback&`;

  if (code) {
    dataStr += `code=${code}`;
  } else if (refreshToken) {
    dataStr += `refresh_token=${refreshToken}`;
  }

  return await fetch(`https://${process.env.REACT_APP_AUTH_DOMAIN}/oauth2/token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: dataStr
  });
};

export const fetchTokens = async (code: string, returnOrigin: string) =>
  await requestTokens(returnOrigin, code);

export const refreshTokens = async (refreshToken: string, returnOrigin: string) =>
  await requestTokens(returnOrigin, undefined, refreshToken);

export const fetchUserInfo = async (idToken: string) =>
  await fetch(`https://${process.env.REACT_APP_AUTH_DOMAIN}/oauth2/userInfo`, {
    method: 'GET',
    headers: {
      Authorization: buildBearerContent(idToken)
    }
  });
