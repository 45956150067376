const timeout = 5000;
let lastTime = Date.now();
let callback;

setInterval(function () {
  const currentTime = Date.now();

  if (currentTime > lastTime + timeout + 2000) {
    callback && callback();
  }
  lastTime = currentTime;
}, timeout);

export const wakeEvent = (newCallback: () => void) => {
  callback = newCallback;
};
