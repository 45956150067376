import React, { FC, useCallback, useState } from 'react';
import CategoriesBrowser from '../components/CategoriesBrowser';
import { useAuth } from '../context/auth/AuthProvider';
import { useUser } from '../context/user/UserProvider';
import { CATEGORY_ITEM_MENU, COMMON_MENU_ITEMS } from '../utils/constants';
import { MenuItem } from '../utils/types';
import { useClickScrollDetection } from '../context/useClickScrollDetectionProvider';
import HeaderLight from './HeaderLight';

const Header: FC = () => {
  const isShownAllComponents = useClickScrollDetection();
  const { isAuthenticated, login } = useAuth();
  const { user } = useUser();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const toggleMenu = useCallback(() => {
    setMobileMenuOpen(!mobileMenuOpen);
  }, [mobileMenuOpen]);

  const menuItemsDesktop: MenuItem[] = [
    isShownAllComponents ? { content: <CategoriesBrowser /> } : CATEGORY_ITEM_MENU,
    ...COMMON_MENU_ITEMS
  ];
  const menuItemsMobile: MenuItem[] = [
    CATEGORY_ITEM_MENU,
    ...COMMON_MENU_ITEMS,
    {
      to: isAuthenticated ? '/account' : '/login',
      text: isAuthenticated ? 'Mijn rekening' : 'Login',
      onClick: isAuthenticated
        ? toggleMenu
        : e => {
            e.preventDefault();
            if (login) {
              login();
              toggleMenu();
            }
          }
    }
  ];

  return (
    <HeaderLight
      isAuthenticated={isAuthenticated}
      menuItemsDesktop={menuItemsDesktop}
      menuItemsMobile={menuItemsMobile}
      mobileMenuOpen={mobileMenuOpen}
      toggleMenu={toggleMenu}
      user={user}
      login={login}
    />
  );
};

export default Header;
