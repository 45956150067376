import { Product } from '@hypercharge/machineland-commons/lib/types/products';
import { DisplayWarehouse, StockCode } from '@hypercharge/machineland-commons/lib/types/stock';
import Button from 'antd/es/button';
import Drawer from 'antd/es/drawer';
import Modal from 'antd/es/modal';
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import Skeleton from 'react-loading-skeleton';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { fetchProduct, fetchWarehouseStock } from '../../actions/products';
import { antdThemToken, media, sizes, theme } from '../../layout/styles';
import RelatedProducts from '../../products/RelatedProducts';
import { getImageUrl } from '../../utils/cms';
import { FetchResponse } from '../../utils/httpClient';
import { Status } from '../../utils/types';
import { history } from '../router/history';
import {
  CATEGORY_ROBOTS_ENTITY_ID,
  FREE_SHIPPING_THRESHOLD,
  NO_IMAGE_SMALL_FILENAME,
  SLUG_SHOPPING_CARD_PAGE
} from '../../utils/constants';
import ConfigProvider from 'antd/es/config-provider';

const AddedProduct = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 150px auto;
  margin-bottom: 1rem;

  ${media.md} {
    grid-template-columns: 150px auto 200px;
  }
`;

const ImgPlaceholder = styled.div`
  height: 55px;
  text-align: center;
  border: 1px solid #eee;
  padding: 0.3rem;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

const ProductTitle = styled.div`
  font-weight: 500;
  color: #333;
`;

const CheckoutButtons = styled.div`
  grid-column: 1 / 3;
  justify-content: center;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: auto auto;

  ${media.md} {
    grid-gap: 0.5rem;
    grid-column: auto;
    grid-template-columns: 1fr;
    justify-content: start;
  }
`;

const ScNotification = styled.div`
  padding: 8px 15px;
  font-size: 16px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum', 'tnum';
  border-radius: 4px;
  margin-bottom: 15px;
  color: #000;
`;

const ScNotificationInfo = styled(ScNotification as any)`
  background-color: ${({ theme }) => theme.mainColorSoft};
  border: 1px solid ${({ theme }) => theme.mainColor};
`;

const ScNotificationAlarm = styled(ScNotification as any)`
  background-color: ${({ theme }) => theme.alarmColor};
  border: 1px solid ${({ theme }) => theme.alarmColorHard};
`;

const ScButton = styled(Button as any)`
  width: 100%;
`;

export type SuggestedProductsContextT = {
  openSuggestions: (productSlug: string) => void;
  closeSuggestions: () => void;
};

const SuggestedProductsContext = createContext<SuggestedProductsContextT | undefined>(undefined);

const InformationLayer = ({
  productSlug,
  closeLayer
}: {
  productSlug: string;
  closeLayer: () => void;
}) => {
  // product itself
  const [product, setProduct] = useState<Product>();
  const [isOutOfStockOnAllWarehouses, setIsOutOfStockOnAllWarehouses] = useState(false);
  const [isProductRobotCategory, setIsProductRobotCategory] = useState(false);
  const [status, setStatus] = useState(Status.Loading);
  const isMd = useMediaQuery({ maxWidth: sizes.md });

  useEffect(() => {
    let productResponse: FetchResponse<Product>;
    let stockResponse: FetchResponse<DisplayWarehouse[] | undefined>;
    const fetchData = async () => {
      if (productSlug) {
        setStatus(Status.Loading);
        try {
          productResponse = fetchProduct(productSlug);
          stockResponse = fetchWarehouseStock(productSlug);
          const [product, stock] = await Promise.all([
            productResponse.promise,
            stockResponse.promise
          ]);

          setProduct(product);
          setIsProductRobotCategory(product.categories.entityId === CATEGORY_ROBOTS_ENTITY_ID);
          if (stock) {
            setIsOutOfStockOnAllWarehouses(
              stock.every(({ stockCode }) => stockCode === StockCode.noStock)
            );
          }
          setStatus(Status.Success);
        } catch (e: unknown) {
          setStatus(Status.Error);
        }
      }
    };

    void fetchData();

    return () => {
      if (productResponse) {
        productResponse.abort();
      }
      if (stockResponse) {
        stockResponse.abort();
      }
    };
  }, [productSlug]);

  const isLoading = status === Status.Loading;

  return (
    <>
      {!isLoading && isOutOfStockOnAllWarehouses && (
        <ScNotificationAlarm>
          Eén van de producten in je winkelmandje is momenteel niet op voorraad en de levertijd moet
          opgevraagd worden. Voor meer informatie kan je contact met ons opnemen.
        </ScNotificationAlarm>
      )}

      <AddedProduct>
        <div>
          {!isLoading && product ? (
            <ImgPlaceholder>
              <img
                alt={`${product.title}`}
                src={
                  product.images?.length
                    ? getImageUrl(product.images[0], 180, 43)
                    : NO_IMAGE_SMALL_FILENAME
                }
              />
            </ImgPlaceholder>
          ) : (
            <Skeleton height="55px" width="100%" />
          )}
        </div>

        <div>
          <ProductTitle>
            {!isLoading && product ? product.title : <Skeleton width="150px" />}
          </ProductTitle>

          <div>{!isLoading && product ? product.description : <Skeleton width="300px" />}</div>
        </div>

        <CheckoutButtons>
          <div>
            <ScButton
              type="primary"
              onClick={() => {
                closeLayer();
                history.push(SLUG_SHOPPING_CARD_PAGE);
              }}
            >
              Ik ga bestellen
            </ScButton>
          </div>

          <div>
            <ScButton onClick={closeLayer}>Verder winkelen</ScButton>
          </div>
        </CheckoutButtons>
      </AddedProduct>

      {!isLoading && (
        <ScNotificationInfo>
          Indien uw aankooptotaal boven de €{FREE_SHIPPING_THRESHOLD} ligt, dan krijgt u gratis
          montage, opstart en thuislevering van het artikel.
          {isProductRobotCategory &&
            ' Dit is niet van toepassing voor producten onder de categorie Robotmaaiers.'}
          <br />
          Als uw aankooptotaal tussen de €400 en €799,99 ligt, dan kan u montage, opstart en
          thuislevering als extra optie bestellen.
        </ScNotificationInfo>
      )}

      <RelatedProducts
        product={product}
        onProductSelected={closeLayer}
        singleRow={!isMd}
        cols={isMd ? 2 : undefined}
      />
    </>
  );
};

const SuggestedProductsProvider = ({ children }: PropsWithChildren) => {
  const [productSlug, setProductSlug] = useState('');
  const isMd = useMediaQuery({ minWidth: sizes.md });

  const openSuggestions = useCallback((productSlug: string) => {
    setProductSlug(productSlug);
  }, []);

  const closeSuggestions = useCallback(() => {
    setProductSlug('');
  }, []);

  const contextObject = useMemo(
    () => ({
      openSuggestions,
      closeSuggestions
    }),
    [openSuggestions, closeSuggestions]
  );

  return (
    <ConfigProvider
      theme={{
        token: antdThemToken
      }}
    >
      <SuggestedProductsContext.Provider value={contextObject}>
        {children}
        {isMd ? (
          <Modal
            open={!!productSlug}
            title="Toegevoegd aan winkelmandje"
            onCancel={closeSuggestions}
            width="80%"
            style={{ maxWidth: theme.width }}
            footer={null}
          >
            <InformationLayer productSlug={productSlug} closeLayer={closeSuggestions} />
          </Modal>
        ) : (
          <Drawer
            open={!!productSlug}
            onClose={closeSuggestions}
            placement="left"
            width="100vw"
            closable={false}
          >
            <h2>Toegevoegd aan winkelmandje</h2>
            <InformationLayer productSlug={productSlug} closeLayer={closeSuggestions} />
          </Drawer>
        )}
      </SuggestedProductsContext.Provider>
    </ConfigProvider>
  );
};

const useSuggestedProducts = (): SuggestedProductsContextT => {
  const context = useContext(SuggestedProductsContext);

  if (context === undefined) {
    throw new Error('useSuggestedProducts must be used within an SuggestedProductsProvider');
  }

  return context;
};

export { useSuggestedProducts, SuggestedProductsProvider };
