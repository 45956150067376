import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { OrderDetails } from '../../types/orders';
import { loadFromSession, saveInSession } from '../../utils/storage';

export type ShoppingOrderContextT = {
  loaded: boolean;
  order?: OrderDetails;
  updateOrderDetails: (order: OrderDetails) => void;
  updateOrderId: (id: string) => void;
  resetOrder: () => void;
};

const storageKey = 'ShoppingOrder';

const ShoppingOrderContext = createContext<ShoppingOrderContextT | undefined>(undefined);

const ShoppingOrderProvider = ({ children }: PropsWithChildren) => {
  const [loaded, setLoaded] = useState(false);
  const [order, setOrder] = useState<OrderDetails>();

  useEffect(() => {
    const storedOrder = loadFromSession<OrderDetails | undefined>(storageKey);

    setOrder(storedOrder);
    setLoaded(true);
  }, []);

  const updateOrderDetails = useCallback((newOrderDetails: OrderDetails) => {
    setOrder(newOrderDetails);
    saveInSession(storageKey, newOrderDetails);
  }, []);

  const updateOrderId = useCallback(
    (id: string) => {
      if (order) {
        const newOrder = { ...order, id };

        setOrder(newOrder);
        saveInSession(storageKey, newOrder);
      }
    },
    [order]
  );

  const resetOrder = useCallback(() => {
    setOrder(undefined);
    saveInSession(storageKey, undefined);
  }, []);

  const contextObject = useMemo(
    () => ({
      loaded,
      order,
      updateOrderDetails,
      updateOrderId,
      resetOrder
    }),
    [loaded, order, updateOrderDetails, updateOrderId, resetOrder]
  );

  return (
    <ShoppingOrderContext.Provider value={contextObject}>{children}</ShoppingOrderContext.Provider>
  );
};

const useShoppingOrder = (): ShoppingOrderContextT => {
  const context = useContext(ShoppingOrderContext);

  if (context === undefined) {
    throw new Error('useShoppingOrder must be used within an ShoppingOrderProvider');
  }

  return context;
};

export { useShoppingOrder, ShoppingOrderProvider };
