import React, { FC, PropsWithChildren } from 'react';
import style from './Common.module.scss';
import { getClassName } from '../utils/helpers';

type WrapperProps = PropsWithChildren<{
  id?: string;
  className?: string;
  htmlContent?: string;
  itemScope?: true;
  itemType?: string;
}>;

export const PageWithWrapper: FC<WrapperProps> = ({ className, children, id }) => {
  return (
    <div className={getClassName(className, style.pageWithWrapper)} id={id}>
      {children}
    </div>
  );
};

export const PaperHeader: FC<WrapperProps> = ({ className, children }) => {
  return <div className={getClassName(className, style.paperHeader)}>{children}</div>;
};

export const Paper: FC<WrapperProps> = ({ className, children, itemScope, itemType }) => {
  return (
    <div className={getClassName(className, style.paper)} itemScope={itemScope} itemType={itemType}>
      {children}
    </div>
  );
};

export const Article: FC<WrapperProps> = ({ className, children }) => {
  return <Paper className={getClassName(className, style.article)}>{children}</Paper>;
};

export const RichDescription: FC<WrapperProps> = ({ htmlContent, className }) => {
  return htmlContent ? (
    <div
      className={getClassName(style.richDescription, className)}
      dangerouslySetInnerHTML={{ __html: htmlContent }}
    />
  ) : null;
};
