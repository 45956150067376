import { Product, ProductLabel } from '@hypercharge/machineland-commons/lib/types/products';
import React, { FC, useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { history } from '../context/router/history';
import AddToCart from '../shopping-cart/AddToCart';
import { getImageUrl } from '../utils/cms';
import Labels from './Labels';
import Price from './Price';
import Rating from './Rating';
import Button from 'antd/es/button';
import { MdRemoveRedEye } from '@react-icons/all-files/md/MdRemoveRedEye';
import style from '../products/Product.module.scss';
import { getClassName } from '../utils/helpers';
import { LOGO_SVG } from '../utils/constants';
import { useClickScrollDetection } from '../context/useClickScrollDetectionProvider';

type PropsT = {
  product?: Product;
  loading?: boolean;
  canAdd?: boolean;
  onClick?: () => void;
  className?: string;
};

const MAX_VISIBLE_LABELS_IN_PRODUCT_LIST = 2;

const ProductComponent: FC<PropsT> = ({ product, canAdd = true, onClick, className }) => {
  const isShownAllComponents = useClickScrollDetection();
  const cantBeOrder = useMemo(
    () => !!product?.labels.find(label => label.entityId === ProductLabel.CANT_BE_ORDER),
    [product]
  );

  return (
    <div className={getClassName(className, style.productWrapper)} itemScope>
      {!!product?.labels.length && (
        <div className={style.labels}>
          <Labels labels={product.labels} maxVisibleLabels={MAX_VISIBLE_LABELS_IN_PRODUCT_LIST} />
        </div>
      )}

      {product ? (
        <div className={style.imgPlaceholder}>
          <Link to={product ? `/${product.slug}` : ''} onClick={onClick}>
            <img
              alt={`${product.title}`}
              src={
                product.images?.length && isShownAllComponents
                  ? getImageUrl(product.images[0], 250, 152)
                  : LOGO_SVG
              }
            />
          </Link>
        </div>
      ) : (
        <div className={style.skeletonImgWrapper}>
          <Skeleton height="152px" />
        </div>
      )}
      <Link to={product ? `/${product.slug}` : ''} onClick={onClick}>
        <div className={style.title}>{product ? product.title : <Skeleton width="150px" />}</div>
      </Link>
      <div className={style.description}>{product ? product.description : <Skeleton />}</div>
      {!canAdd && (
        <div className={style.variantsDescription}>
          {product?.variantDescriptions
            ?.split(',')
            .map((description, index) => <span key={description + index}>{description}</span>) ||
            ''}
        </div>
      )}
      <div className={style.twoCols}>
        <div>
          <Rating
            className={style.rating}
            withMetaData={false}
            value={
              product?.rating
                ? Number(product.rating.entityId ? product.rating.entityId : product.rating)
                : 0
            }
          />
          {product ? (
            <div>
              <Price
                price={product.price}
                previousPrice={product.previousPrice}
                withMetaData={false}
                classNames={{
                  currentPrice: style.currentPrice,
                  previousPrice: style.previousPrice
                }}
              />
            </div>
          ) : (
            <Skeleton width="100px" />
          )}
        </div>

        <div>
          {product ? (
            product.canOrder === 'no' || cantBeOrder ? (
              <Button
                className={style.button}
                type="primary"
                onClick={() => {
                  history.push(`/${product.slug}`);
                }}
              >
                <MdRemoveRedEye className={style.eyeIcon} />
              </Button>
            ) : (
              <AddToCart
                className={style.addToCart}
                product={product}
                onClick={
                  !canAdd
                    ? () => {
                        history.push(`/${product.slug}`);
                      }
                    : undefined
                }
              />
            )
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ProductComponent;
