import React, { FC, PropsWithChildren } from 'react';
import Helmet from 'react-helmet';
import ErrorBoundary from '../components/ErrorBoundary';
import Footer from './Footer';
import Header from './Header';
import { PageWithWrapper } from './Common';
import HeaderLight from './HeaderLight';
import { useClickScrollDetection } from '../context/useClickScrollDetectionProvider';
import Skeleton from 'react-loading-skeleton';

type PropsT = PropsWithChildren<{
  isLayoutLight?: boolean;
}>;

const Layout: FC<PropsT> = ({ children, isLayoutLight = false }) => {
  const isShownAllComponents = useClickScrollDetection();

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Uw specialist in tuinmachines, met het ruimste aanbod van grasmaaiers tot kettingzagen. U kan rekenen op onze gekwalificeerde technische ploeg voor professioneel advies op maat."
        />
        {process.env.REACT_APP_ENV !== 'prod' && <meta name="robots" content="noindex" />}
      </Helmet>
      <div className="main-content-wrapper">
        {!isLayoutLight ? <Header /> : <HeaderLight />}
        <PageWithWrapper id="main-content">
          <ErrorBoundary>{children}</ErrorBoundary>
        </PageWithWrapper>
        {!isLayoutLight && isShownAllComponents ? (
          <Footer />
        ) : (
          <div>
            <Skeleton className="cp-md-hide" height="838px" />
            <Skeleton className="cp-hide cp-md-show-block" height="322px" />
          </div>
        )}
      </div>
    </>
  );
};

export default Layout;
