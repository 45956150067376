import { hyperfetch, FetchResponse } from '../utils/httpClient';

type Vacancies = {
  entityId: string;
  title: string;
  jobTitle: string;
  location: string;
  description: string;
  image: string[];
  responsibilities: string[];
  active: boolean;
  subText: any;
};

type VacanciesResponse = {
  results: Vacancies[];
  totalCount: number;
};

export const fetchVacancies = (): FetchResponse<VacanciesResponse> =>
  hyperfetch('/api/vacancies', {
    method: 'GET'
  });
