import { Address, PaginatedList } from '@hypercharge/machineland-commons/lib/types/common';
import { OrderExpanded } from '@hypercharge/machineland-commons/lib/types/orders';
import { ProductFavorite } from '@hypercharge/machineland-commons/lib/types/products';
import { ProductReview } from '@hypercharge/machineland-commons/lib/types/reviews';
import { User } from '@hypercharge/machineland-commons/lib/types/user';

import { FetchResponse, hyperfetch } from '../utils/httpClient';

export const fetchMyUser = (): FetchResponse<User> => hyperfetch('/api/private/account/me');

export const updateUserProfile = (
  firstName: string,
  lastName: string,
  email: string,
  billingAddress: Address,
  shippingAddress: Address
): FetchResponse<void> =>
  hyperfetch('/api/private/account/update-profile', {
    method: 'PUT',
    body: JSON.stringify({
      firstName,
      lastName,
      email,
      billingAddress,
      shippingAddress
    })
  });

export const fetchUserOrders = (offset: number, limit: number) =>
  hyperfetch<PaginatedList<OrderExpanded>>(`/api/private/account/orders/${offset}/${limit}`, {
    method: 'GET'
  });

export const fetchUserOrder = (orderId: string) =>
  hyperfetch<OrderExpanded | undefined>(`/api/private/account/order/${orderId}`, {
    method: 'GET'
  });

export const fetchUserReviews = (offset: number, limit: number) =>
  hyperfetch<PaginatedList<ProductReview>>(`/api/private/account/reviews/${offset}/${limit}`, {
    method: 'GET'
  });

export const fetchUserReview = (reviewId: string) =>
  hyperfetch<ProductReview | undefined>(`/api/private/account/review/${reviewId}`, {
    method: 'GET'
  });

export const fetchUserFavorites = (offset: number, limit: number) =>
  hyperfetch<PaginatedList<ProductFavorite>>(`/api/private/account/favorites/${offset}/${limit}`, {
    method: 'GET'
  });
