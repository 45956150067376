import React, { FC, useMemo } from 'react';
import { useShoppingCart } from '../context/shopping-cart/ShoppingCartProvider';
import ShoppingCartLight from './ShoppingCartLight';

type PropsT = {
  className?: string;
};

const ShoppingCart: FC<PropsT> = ({ className }) => {
  const { getItemCount } = useShoppingCart();
  const count = useMemo(() => getItemCount && getItemCount(), [getItemCount]);

  return <ShoppingCartLight className={className} count={count} />;
};

export default ShoppingCart;
