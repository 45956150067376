import { showError } from './notifications';

export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) {
    return '0 B';
  }
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

const saveInStorage = (
  storage: Storage | undefined,
  storageKey: string,
  data: unknown | undefined
): void => {
  try {
    if (storage) {
      if (data != null && data !== undefined) {
        storage.setItem(storageKey, JSON.stringify(data));
      } else {
        storage.removeItem(storageKey);
      }
    }
  } catch (e: unknown) {
    showError('Could not access browser local/session storage');
  }
};

const loadFromStorage = <T>(
  storage: Storage | undefined,
  localStorageKey: string
): T | undefined => {
  try {
    if (storage) {
      const dataStr = storage.getItem(localStorageKey);

      return dataStr ? JSON.parse(dataStr) : undefined;
    } else {
      return undefined;
    }
  } catch (e: unknown) {
    showError('Could not access browser local/session storage');

    return undefined;
  }
};

export const saveInLocalStorage = (storageKey: string, data: unknown | undefined): void => {
  saveInStorage(window.localStorage, storageKey, data);
};

export const loadFromLocalStorage = <T>(storageKey: string): T | undefined =>
  loadFromStorage(window.localStorage, storageKey) || undefined;

export const saveInSession = (storageKey: string, data: unknown | undefined) => {
  saveInStorage(window.sessionStorage, storageKey, data);
};

export const loadFromSession = <T>(storageKey: string): T | undefined =>
  loadFromStorage(window.sessionStorage, storageKey) || undefined;
