import {
  AggregationType,
  ConditionQuery,
  ConditionType,
  Filter,
  FilterOperatorTypes,
  Range
} from '@hypercharge/machineland-commons/lib/types/search';
import set from 'lodash/set';
import { ParsedQuery } from 'query-string';
import React, { createContext, PropsWithChildren, useCallback, useContext, useMemo } from 'react';

import {
  CATEGORIES_AGGREGATION_FIELD,
  CATEGORY_GROUP_PARAM,
  DEFAULT_SORT_KEY,
  RELEVANCE_SORT_KEY,
  TERM_FILTERS_PREFIX
} from '../../utils/constants';
import { useQuery } from '../router/UrlQueryProvider';
import { getFiltersWithUpselling } from './utils';
import { PRODUCTS_PER_PAGE } from '@hypercharge/machineland-commons/lib/constants';

export type SearchParams = {
  filters: ConditionQuery;
  page: number;
  pageSize: number;
  sortBy: string;
  textQuery?: string;
};

export type AggSearchParam =
  | {
      data: string[];
      field: string;
      type: AggregationType.term;
    }
  | {
      data: Range;
      field: string;
      type: AggregationType.range;
    };

type ContextValue = {
  aggParams: AggSearchParam[];
  clearParam: (param: string) => void;
  clearAggParam: (field: string, type: AggregationType) => void;
  clearAll: () => void;
  params: SearchParams;
  setAggParam: (aggParam: AggSearchParam) => void;
  setParam: (param: string, value: string | number) => void;
};

export const PAGE_NUMBER_PARAM = 'page';
export const PAGE_SIZE_PARAM = 'pageSize';
export const TEXT_PARAM = 'q';
export const SORT_PARAM = 's';
const NON_AGG_PARAMS = [TEXT_PARAM, PAGE_NUMBER_PARAM, PAGE_SIZE_PARAM, SORT_PARAM];

const RANGE_FILTERS_PREFIX = 'fr';

const FILTERS_REGEX = new RegExp(`^(${TERM_FILTERS_PREFIX}|${RANGE_FILTERS_PREFIX})_(.+)$`);
const RANGE_REGEX = /^(\d+|\*)-(\d+|\*)$/;

export const SearchParamsContext = createContext<ContextValue | undefined>(undefined);

export const SearchParamsProvider = ({ ...otherProps }: PropsWithChildren) => {
  const { query, setQueryParams, removeQueryParams } = useQuery();
  const params = useMemo(() => getSearchParams(query), [query]);
  const aggParams = useMemo(() => getAggSearchParams(query), [query]);

  const setAggParam = useCallback(
    (aggParam: AggSearchParam) => {
      const queryParam: string = getQueryParam(aggParam.field, aggParam.type);
      const data: string | string[] = toQueryParamValue(aggParam);

      const queryParams = [
        { key: queryParam, value: data },
        { key: PAGE_NUMBER_PARAM, value: '1' }
      ];

      // there goes the abstraction
      // clear the query group param when more than one
      // category has now been selected
      if (aggParam.field === CATEGORIES_AGGREGATION_FIELD) {
        const values = aggParam.data as string[];

        if (values.length === 0 || values.length > 1) {
          queryParams.push({ key: CATEGORY_GROUP_PARAM } as any);
        }
      }

      setQueryParams(...queryParams);
    },
    [setQueryParams]
  );

  const setParam = useCallback(
    (param: string, value: string | number) => {
      const changes = [{ key: param, value: value.toString() }];

      // Note: Every time a search parameter changes, we must reset the page number
      // (unless it is the page number parameter that changed).
      if (param !== PAGE_NUMBER_PARAM) {
        changes.push({ key: PAGE_NUMBER_PARAM, value: '1' });
      }

      // If a new text query is given, make the sort be by relevance,
      // if after that the user specifies a different sort order, allow it
      if (param === TEXT_PARAM && value) {
        changes.push({ key: SORT_PARAM, value: RELEVANCE_SORT_KEY });
      }

      // If I clear the text query, it no longer makes sense to ask a sort by relevance
      if (param === TEXT_PARAM && !value && params.sortBy === RELEVANCE_SORT_KEY) {
        changes.push({ key: SORT_PARAM, value: DEFAULT_SORT_KEY });
      }

      setQueryParams(...changes);
    },
    [params.sortBy, setQueryParams]
  );

  const clearAll = useCallback(() => {
    removeQueryParams(...NON_AGG_PARAMS, ...getAggParams(query));
  }, [query, removeQueryParams]);

  const clearParam = useCallback(
    (queryParam: string) => {
      removeQueryParams(queryParam, PAGE_NUMBER_PARAM);
    },
    [removeQueryParams]
  );

  const clearAggParam = useCallback(
    (field: string, type: AggregationType) => {
      const queryParam: string = getQueryParam(field, type);

      removeQueryParams(queryParam, PAGE_NUMBER_PARAM);
    },
    [removeQueryParams]
  );

  const value = useMemo(
    () => ({
      aggParams,
      clearParam,
      clearAggParam,
      clearAll,
      params,
      setAggParam,
      setParam
    }),
    [aggParams, clearParam, clearAggParam, clearAll, params, setAggParam, setParam]
  );

  return <SearchParamsContext.Provider value={value} {...otherProps} />;
};

export const useSearchParams = (): ContextValue => {
  const query = useContext(SearchParamsContext);

  if (query === undefined) {
    throw new Error('useSearchParams must be used inside a SearchParamsProvider');
  }

  return query;
};

//
//
// Utilities
//
//

const getAggParams = (query: ParsedQuery): string[] =>
  Object.keys(query).filter(p => FILTERS_REGEX.test(p));

const getQueryParam = (field: string, type: AggregationType): string => {
  switch (type) {
    case AggregationType.range:
      return `${RANGE_FILTERS_PREFIX}_${field}`;
    case AggregationType.term:
      return `${TERM_FILTERS_PREFIX}_${field}`;
  }

  return '';
};

const toQueryParamValue = (aggParam: AggSearchParam): string | string[] => {
  switch (aggParam.type) {
    case AggregationType.range:
      return aggParam.data.start ||
        aggParam.data.start === 0 ||
        aggParam.data.end ||
        aggParam.data.end === 0
        ? `${aggParam.data.start || aggParam.data.start === 0 ? aggParam.data.start : '*'}-${
            aggParam.data.end || aggParam.data.end === 0 ? aggParam.data.end : '*'
          }`
        : [];
    case AggregationType.term:
      return aggParam.data;
  }

  return '';
};

const parseRangeValue = (value: string) => {
  const rangeMatch = value.match(RANGE_REGEX);

  if (rangeMatch == null || (rangeMatch[1] === '*' && rangeMatch[2] === '*')) {
    throw new Error('Invalid range parameter');
  }

  return [
    rangeMatch[1] === '*' ? undefined : Number(rangeMatch[1]),
    rangeMatch[2] === '*' ? undefined : Number(rangeMatch[2])
  ];
};

const fromQueryParamValue = (
  field: string,
  type: AggregationType,
  data: string[] | string
): AggSearchParam => {
  switch (type) {
    case AggregationType.range:
      const rangeMatch = (data as string).match(RANGE_REGEX);

      if (rangeMatch == null || (rangeMatch[1] === '*' && rangeMatch[2] === '*')) {
        throw new Error('Invalid range parameter');
      }
      const [start, end] = parseRangeValue(data as string);

      return {
        field,
        type,
        data: {
          start,
          end
        } as Range
      };
    case AggregationType.term:
      return {
        field,
        type,
        data: data as string[]
      };
  }

  return {
    field,
    type,
    data: data as string[]
  };
};

const toAggType = (prefix: string): AggregationType => {
  switch (prefix) {
    case RANGE_FILTERS_PREFIX:
      return AggregationType.range;
    case TERM_FILTERS_PREFIX:
    default:
      return AggregationType.term;
  }
};

const getSearchParams = (query: ParsedQuery) => ({
  page: getPageNumber(query),
  textQuery: getTextQuery(query),
  filters: getConditionQuery(query),
  pageSize: getPageSize(query),
  sortBy: getSort(query)
});

const getPageSize = (query: ParsedQuery): number =>
  query[PAGE_SIZE_PARAM] ? Number(query[PAGE_SIZE_PARAM]) : PRODUCTS_PER_PAGE;

const getPageNumber = (query: ParsedQuery): number =>
  query[PAGE_NUMBER_PARAM] ? Number(query[PAGE_NUMBER_PARAM]) : 1;

const getTextQuery = (query: ParsedQuery): string | undefined =>
  query[TEXT_PARAM] as string | undefined;

const getSort = (query: ParsedQuery): string =>
  query[SORT_PARAM] ? (query[SORT_PARAM] as string) : DEFAULT_SORT_KEY;

const getAggSearchParams = (query: ParsedQuery): AggSearchParam[] => {
  const params: AggSearchParam[] = [];

  for (const [param, value] of Object.entries(query)) {
    if (value != null) {
      const match = param.match(FILTERS_REGEX);

      if (match != null) {
        const prefix = match[1];
        const field = match[2];
        const aggType = toAggType(prefix);
        const aggParam = fromQueryParamValue(field, aggType, value);

        params.push(aggParam);
      }
    }
  }

  return params;
};

const getConditionQuery = (query: ParsedQuery): ConditionQuery => {
  const filtersByFieldOp: Record<string, { [op in FilterOperatorTypes]: Filter[] }> = {};

  for (const [param, value] of Object.entries(query)) {
    const match = param.match(FILTERS_REGEX);

    if (match != null) {
      const filterType = match[1];
      const field = match[2];

      switch (filterType) {
        case TERM_FILTERS_PREFIX:
          const fieldFilters: Filter[] = (value as string[]).map((v: string) => ({
            data: v,
            field,
            operator: FilterOperatorTypes.is
          }));

          set(filtersByFieldOp, [field, FilterOperatorTypes.is], fieldFilters);
          break;
        case RANGE_FILTERS_PREFIX:
          const [start, end] = parseRangeValue(value as string);

          set(
            filtersByFieldOp,
            [field, FilterOperatorTypes.greaterThanOrEqual],
            [
              // this is a hack to pass the value to the
              // aggregation service that expects {start, end} data
              {
                operator: FilterOperatorTypes.between,
                data: { start, end },
                field
              }
            ]
          );
          break;
        default:
        // do nothing
      }
    }
  }

  const filters: Array<ConditionQuery | Filter> = [];

  for (const field of Object.keys(filtersByFieldOp)) {
    const filtersByOp = filtersByFieldOp[field];

    for (const [fieldOp, fieldFilters] of Object.entries(filtersByOp)) {
      // injecting upselling here works because the both AggregationsProvider and
      // SearchResultsProvider use the params for the query but AggregationsProvider
      // usess the aggParams for UI display
      const filtersWithUpselling = getFiltersWithUpselling(field, fieldFilters);

      if (filtersWithUpselling.length > 1) {
        filters.push({
          // looking for the operator is not the cleanest solution
          // maybe use the type for the second level grouping?
          condition:
            fieldOp === FilterOperatorTypes.greaterThanOrEqual
              ? ConditionType.and
              : ConditionType.or,
          filters: filtersWithUpselling
        });
      } else {
        filters.push(filtersWithUpselling[0]);
      }
    }
  }

  return { condition: ConditionType.and, filters };
};
