import { COUNTRIES_OPTIONS, DEFAULT_COUNTRY } from '@hypercharge/machineland-commons/lib/constants';

export const getCountryLabel = (country: string) => {
  const countryOption = COUNTRIES_OPTIONS.find(opt => opt.value === country);

  return countryOption ? countryOption.label : getCountryLabel(DEFAULT_COUNTRY);
};

export const getClassName = (...args: Array<string | boolean | undefined | null>): string => {
  return [...args]
    .filter(i => i && typeof i !== 'boolean')
    .map(arg => arg?.toString().trim())
    .join(' ');
};
