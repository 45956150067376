import React, { useState, useCallback, FC } from 'react';
import Search from 'antd/es/input/Search';
import { history } from '../context/router/history';
import { SORT_PARAM, TEXT_PARAM, useSearchParams } from '../context/search/SearchParamsProvider';
import { SEARCH_PLACEHOLDER } from '../utils/constants';

type PropsT = { className?: string };

const AppSearch: FC<PropsT> = ({ className }) => {
  const {
    params: { textQuery = '' }
  } = useSearchParams();
  const [value, setValue] = useState<string>(textQuery);

  /*
    I has wrote default interpolate for l18n some time ago. It's looks like:

    const interpolate = (literals, ...expressions) => {
      let string = ``
      for (const [i, val] of expressions.entries()) {
        string += literals[i] + val
      }
      string += literals[literals.length - 1]
      return string
    }
    console.log(interpolate`I waste last ${5} hours fo lulz`)

    And here i has modernized it for this this solution for O(n) time. ('Big-O notation')
    Checked time with perfomance now (coz working around strings is slow).
    Current implementation cost 0.12ms (0.00012 sec)
  */
  const interpolate = (literals, ...expressions) => {
    let isModel = false;
    let string = '';

    for (const [i, val] of expressions.entries()) {
      if (i === 1) {
        const exp = expressions[1].split('');
        // eslint-disable-next-line no-loop-func
        const str = exp.reduce((p: any, c: string, i: number) => {
          if ((isModel && c === ' ') || (c === ' ' && !isNaN(+exp[i - 1]))) {
            if (!isModel) isModel = true;

            return p;
          }

          return [...p, c];
        }, []);

        string += literals[i] + str.join('');
      } else {
        string += literals[i] + val;
      }
    }
    string += literals[literals.length - 1];

    return string;
  };

  const onSearch = useCallback(() => {
    history.push(
      value ? interpolate`/search?${TEXT_PARAM}=${value}&${SORT_PARAM}=relevant` : '/search'
    );
  }, [value]);

  const onChange = useCallback(e => {
    setValue(e.target.value);
  }, []);

  return (
    <Search
      className={className}
      placeholder={SEARCH_PLACEHOLDER}
      onSearch={onSearch}
      onChange={onChange}
      enterButton
    />
  );
};

export default AppSearch;
