import { Product as ProductT } from '@hypercharge/machineland-commons/lib/types/products';
import Carousel from 'antd/es/carousel';
import chunk from 'lodash/chunk';
import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components/macro';
import { media, sizes } from '../layout/styles';
import Product from './Product';

const Wrapper = styled.div`
  .slick-slider {
    padding: 10px 0 45px 0;
  }

  .ant-carousel .slick-dots {
    height: 15px;
    bottom: 0;
    li {
      width: 20px;
    }

    button {
      height: 15px;
      border-radius: 100px;
    }

    button,
    li.slick-active button {
      width: 15px;
      background: ${props => props.theme.mainColorActive};
    }
  }
`;

const ScResults = styled.div<{ singleRow: boolean; cols: number }>`
  display: grid;

  ${media.md} {
    grid-auto-flow: ${props => (props.singleRow ? 'column' : 'unset')};
    grid-template-columns: ${props => Array(props.cols).fill('1fr').join(' ')};
  }

  > * {
    border-bottom: 1px solid #eee;

    &:last-child {
      border-bottom: none;
    }

    ${media.md} {
      border-right: 1px solid #eee;

      &:nth-child(-n + ${props => props.cols - 1}) {
        border-bottom: 1px solid #eee;
      }

      &:nth-child(${props => props.cols}n) {
        border-right: none;
      }

      /* The :nth-child(Xn+1) pseudo-class will target every xth element in the grid, 
         which will be the first item in each row. The :nth-last-child(-n+X) pseudo-class will 
         target the last x elements in the grid. Combining these pseudo-classes will only target 
         elements that match both, which in our case is the first element in the last row of the grid. */

      &:nth-child(${props => props.cols}n+1):nth-last-child(-n + ${props => props.cols}),
      &:nth-child(${props => props.cols}n+1):nth-last-child(-n + ${props => props.cols}) ~ div {
        border-bottom: none;
      }
    }
  }
`;

const ScProductWrapper = styled.div`
  width: 100%;
`;

const ScProduct = styled(Product as any)`
  max-width: 296px;
  margin: 0 auto;
`;

const ProductCard = ({ ...props }) => {
  return (
    <ScProductWrapper>
      <ScProduct {...props} />
    </ScProductWrapper>
  );
};

type PropsT = {
  products: ProductT[];
  singleRow?: boolean;
  loadingPlaceholders?: number;
  onProductSelected?: () => void;
  cols?: number;
};

const ProductList: FC<PropsT> = ({
  products,
  singleRow = false,
  loadingPlaceholders,
  onProductSelected,
  cols
}) => {
  const chunkedProducts = singleRow ? chunk(products, 4) : [products];
  const isMd = useMediaQuery({ minWidth: sizes.md });

  if (!cols) {
    cols = singleRow ? 4 : 3;
  }

  return (
    <Wrapper>
      {chunkedProducts.length > 1 && !loadingPlaceholders && isMd ? (
        <Carousel swipeToSlide draggable>
          {chunkedProducts.map((products, i) => (
            <div key={i}>
              <ScResults singleRow={singleRow} cols={cols as number}>
                {loadingPlaceholders
                  ? [...Array(loadingPlaceholders)].map((_, i) => <ProductCard key={i} loading />)
                  : products.map(res => (
                      <ProductCard
                        product={res}
                        key={res.entityId}
                        onClick={onProductSelected}
                        canAdd={!res.masterProduct}
                      />
                    ))}
              </ScResults>
            </div>
          ))}
        </Carousel>
      ) : (
        <ScResults
          singleRow={singleRow}
          cols={cols > products.length && !loadingPlaceholders ? products.length : cols}
        >
          {loadingPlaceholders
            ? [...Array(loadingPlaceholders)].map((_, i) => <ProductCard key={i} loading />)
            : products.map(res => (
                <ProductCard
                  product={res}
                  key={res.entityId}
                  onClick={onProductSelected}
                  canAdd={!res.masterProduct}
                />
              ))}
        </ScResults>
      )}
    </Wrapper>
  );
};

export default ProductList;
