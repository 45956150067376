import Popover from 'antd/es/popover';
import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { MdKeyboardArrowDown } from '@react-icons/all-files/md/MdKeyboardArrowDown';
import { titleToUrlEncode } from '@hypercharge/machineland-commons/lib/utils/seo';
import { useCategoryGroups } from '../context/secondaryUseQueryProviders';
import Skeleton from 'react-loading-skeleton';
import style from './CategoriesBrowser.module.scss';
import { useClickScrollDetection } from '../context/useClickScrollDetectionProvider';

// This is first item of main header menu with category tree
const CategoriesBrowser: FC = () => {
  const isShownAllComponents = useClickScrollDetection();
  const { data: categoryGroups, isLoading } = useCategoryGroups({
    enabled: isShownAllComponents
  });
  const [visible, setVisible] = useState(false);

  return (
    <Popover
      overlayClassName="categories-browser"
      placement="bottomLeft"
      trigger="hover"
      onOpenChange={setVisible}
      open={visible}
      content={
        <>
          {isLoading || !categoryGroups ? (
            <Skeleton width="100%" height="200px" />
          ) : (
            <div className={style.popupMenu}>
              <ul className={style.groupsUl}>
                {categoryGroups.map(group => (
                  <li key={group.entityId}>
                    <div>{group.title}</div>
                    <ul className={style.categoriesUl}>
                      {group.categories?.map(({ title }) =>
                        title ? (
                          <li key={title}>
                            <Link
                              key={title}
                              to={`/categories/${group.slug}/${titleToUrlEncode(title)}${
                                group.filterQuery ? `?${group.filterQuery}` : ''
                              }`}
                              onClick={() => {
                                setVisible(false);
                              }}
                            >
                              {title}
                            </Link>
                          </li>
                        ) : null
                      )}

                      {group.slug === 'professioneel-materiaal' && group.brands && (
                        <>
                          {group.brands.map(({ title }) => (
                            <li key={title}>
                              <Link
                                to={`/prof-materiaal?ft_brand.title[]=${encodeURIComponent(
                                  title.toLocaleLowerCase()
                                )}`}
                                onClick={() => {
                                  setVisible(false);
                                }}
                              >
                                {title}
                              </Link>
                            </li>
                          ))}

                          <li>
                            <Link
                              to="/prof-materiaal"
                              onClick={() => {
                                setVisible(false);
                              }}
                            >
                              Overige
                            </Link>
                          </li>
                        </>
                      )}
                    </ul>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </>
      }
    >
      <Link to="/categories">
        Hoofdcategorieën <MdKeyboardArrowDown className={style.arrowDown} />
      </Link>
    </Popover>
  );
};

export default CategoriesBrowser;
