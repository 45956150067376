import React, { Suspense } from 'react';
import { Router } from 'react-router';
import { ThemeProvider } from 'styled-components/macro';
import { antdThemToken, theme } from '../layout/styles';
import { AuthProvider } from './auth/AuthProvider';
import { history } from './router/history';
import { UrlQueryProvider } from './router/UrlQueryProvider';
import { SearchParamsProvider } from './search/SearchParamsProvider';
import { ShoppingCartProvider } from './shopping-cart/ShoppingCartProvider';
import { SuggestedProductsProvider } from './shopping-cart/SuggestedProductsProvider';
import { UserProvider } from './user/UserProvider';
import { ShoppingOrderProvider } from './checkout/ShoppingOrderProvider';
import { QueryClient, QueryClientProvider } from 'react-query';
import { UseClickScrollDetectionProvider } from './useClickScrollDetectionProvider';
import ConfigProvider from 'antd/es/config-provider';
import Layout from '../layout/Layout';

export const AppProviders = ({ children }: any) => {
  const queryClient = new QueryClient();

  return (
    <Router history={history}>
      <ThemeProvider theme={theme}>
        <UrlQueryProvider>
          <UseClickScrollDetectionProvider>
            <ConfigProvider
              theme={{
                token: antdThemToken
              }}
            >
              <Suspense fallback={<Layout isLayoutLight />}>
                <QueryClientProvider client={queryClient}>
                  <AuthProvider>
                    <UserProvider>
                      <ShoppingCartProvider>
                        <SuggestedProductsProvider>
                          <ShoppingOrderProvider>
                            <SearchParamsProvider>{children}</SearchParamsProvider>
                          </ShoppingOrderProvider>
                        </SuggestedProductsProvider>
                      </ShoppingCartProvider>
                    </UserProvider>
                  </AuthProvider>
                </QueryClientProvider>
              </Suspense>
            </ConfigProvider>
          </UseClickScrollDetectionProvider>
        </UrlQueryProvider>
      </ThemeProvider>
    </Router>
  );
};
