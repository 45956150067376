import { PaginatedList } from '@hypercharge/machineland-commons/lib/types/common';
import { Product, ProductLabel } from '@hypercharge/machineland-commons/lib/types/products';
import { TRANSPORT_PRODUCT_TITLE } from '@hypercharge/machineland-commons/lib/constants';
import {
  AggregationResultSet,
  ConditionQuery,
  ConditionType,
  Filter,
  FilterOperatorTypes,
  FilterRequest
} from '@hypercharge/machineland-commons/lib/types/search';
import { DisplayWarehouse } from '@hypercharge/machineland-commons/lib/types/stock';
import { FetchResponse, hyperfetch, json } from '../utils/httpClient';

export const searchProducts = (filter: FilterRequest): FetchResponse<PaginatedList<Product>> =>
  hyperfetch<PaginatedList<Product>>('/api/products/search/', {
    method: 'POST',
    body: json(filter)
  });

export const findProductsByEntityId = async (ids: string[]): Promise<Product[]> => {
  const resp = await searchProducts({
    offset: 0,
    limit: ids.length,
    languageCode: 'nl',
    query: {
      condition: ConditionType.or,
      filters: ids.map(id => {
        const filter: Filter = { field: 'entityId', operator: FilterOperatorTypes.is, data: id };

        return filter;
      })
    }
  }).promise;

  return resp.results;
};

export const getMontageProducts = async (): Promise<Product[]> => {
  const { results } = await searchProducts({
    offset: 0,
    limit: 2,
    languageCode: 'nl',
    query: {
      condition: ConditionType.or,
      filters: [
        {
          field: 'isMontage',
          operator: FilterOperatorTypes.equals,
          data: true
        }
      ]
    }
  }).promise;

  return results;
};

export const getTransportProduct = async (): Promise<Product> => {
  const {
    results: [transportItem]
  } = await searchProducts({
    offset: 0,
    limit: 1,
    languageCode: 'nl',
    query: {
      condition: ConditionType.and,
      filters: [
        {
          field: 'isMiscellaneous',
          operator: FilterOperatorTypes.equals,
          data: true
        },
        {
          field: 'title',
          operator: FilterOperatorTypes.is,
          data: TRANSPORT_PRODUCT_TITLE
        }
      ]
    }
  }).promise;

  return transportItem;
};

export const fetchProductsAggregations = (
  facetsQuery: ConditionQuery,
  query?: ConditionQuery,
  fullText?: string
) =>
  hyperfetch<AggregationResultSet>('/api/products/search/aggregations', {
    method: 'POST',
    body: json({
      query,
      facetsQuery,
      fullText
    })
  });

export const fetchProduct = (productSlug: string, isAuthenticated = false) =>
  hyperfetch<Product>(`/api/${isAuthenticated ? 'private/' : ''}products/${productSlug}`, {
    method: 'GET'
  });

export const fetchTopSoldProducts = () =>
  hyperfetch<Product[]>('/api/products/top-sold/8', {
    method: 'GET'
  });

export const searchRecommendedProducts = (): FetchResponse<PaginatedList<Product>> =>
  searchProducts({
    offset: 0,
    limit: 8,
    languageCode: 'nl',
    query: {
      condition: ConditionType.and,
      filters: [
        {
          field: 'labels.entityId',
          data: ProductLabel.HOMEPAGE,
          operator: FilterOperatorTypes.contains
        }
      ]
    }
  });

export const fetchOfflineProductAlternatives = (productSlug: string) =>
  hyperfetch<Product[]>(`/api/products/offline/${productSlug}/alternatives`, {
    method: 'GET'
  });

export const fetchWarehouseStock = (productSlug: string) =>
  hyperfetch<DisplayWarehouse[] | undefined>(`/api/products/stock/${productSlug}`, {
    method: 'GET'
  });
